import React from "react";
import "./AboutPage.scss";
import Appbar from "../Appbar";
import MobileView from "../MobileView";
import Footer from "../Footer";
import { withBreakpoints } from "react-breakpoints";

function AboutPage({ currentBreakpoint, breakpoints }) {
  return breakpoints[currentBreakpoint] > breakpoints.mobileLandscape ? (
    <div className="about-page-wrapper">
      <Appbar />
      <div className="about-container">
        <div className="publications">
          <div className="rel-container">
            <h2 className="about-header rel">RELATED PUBLICATIONS</h2>
            <ul className="about-list">
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="about-link al-1"
                  href="https://injuryprevention.bmj.com/content/25/4/252"
                >
                  Canadian Child Safety Report Card: a comparison of injury
                  prevention practices across provinces
                  <br />
                  <br />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="about-link al-2"
                  href="https://bmcpublichealth.biomedcentral.com/track/pdf/10.1186/s12889-018-6269-9"
                >
                  Childhood road traffic injuries in Canada – a provincial
                  comparison of transport injury rates over time
                  <br />
                  <br />
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  className="about-link al-3"
                  href="https://www.ncbi.nlm.nih.gov/pubmed/30073552"
                >
                  An interprovincial comparison of unintentional childhood
                  injury rates in Canada for the period 2006-2012
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="acknowledgments">
          <div className="ack-container">
            <h2 className="about-header ack">ACKNOWLEDGEMENTS</h2>
            <p className="about-para">
              This report card would not be possible without the valuable
              contributions from the Canadian Collaborating Centres for Injury
              Prevention (CCCIP), Parachute Canada, and the coroners/medical
              examiners in each of the participating provinces.
            </p>
          </div>
          <div className="logos-container">
            <div
              className="logo-bcch"
              style={{
                backgroundImage: `url(/logo/digital-lab.svg)`,
                backgroundRepeat: "no-repeat"
              }}
            ></div>
            <div
              className="logo-cihr"
              style={{
                backgroundImage: `url(/logo/cihr.jpg)`,
                backgroundRepeat: "no-repeat"
              }}
            ></div>
          </div>
        </div>
        <div className="contact">
          <div className="contact-1-container">
            <span className="about-header contact-1 con">CONTACT US</span>
          </div>
          <div className="contact-2">
            <h3 className="about-sub-header">Head office</h3>
            <p className="about-sub-info">
              150 Eglinton Ave East
              <br /> Suite 300 <br />
              Toronto, Ontario
              <br /> M4P 1E8
            </p>
          </div>
          <div className="contact-3">
            <div>
              <h3 className="about-sub-header">Telephone</h3>
              <p className="about-sub-info">647-776-5100</p>
              <h3 className="about-sub-header">Toll-free</h3>
              <p className="about-sub-info">1-888-537-7777</p>
            </div>
          </div>
          <div className="contact-4">
            <div>
              <h3 className="about-sub-header">Email</h3>
              <p className="about-sub-info">info@parachute.ca</p>
              <h3 className="about-sub-header">
                For media enquired, please contact
              </h3>
              <p className="about-sub-info"> media@parachute.ca </p>
            </div>
          </div>
        </div>
      </div>
      <Footer className="footer-container" />
    </div>
  ) : (
    <MobileView />
  );
}

export default withBreakpoints(AboutPage);
