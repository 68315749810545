import React, { useState, useEffect } from "react";
import "./BarChartBackground.scss";
import BarChartHome from "../BarChartHome";
import BarChart from "../BarChart";
import { CHARTCOLORSBAR, INJURIES } from "../../constants.js";
import { withRouter } from "react-router";
import data from "../../data.js";
import { Accordion, Card } from "react-bootstrap";
import ReactGA from "react-ga";

function BarChartBackground({ match }) {
  const { id } = match.params;
  const [injury, setInjury] = useState("Falls");
  const injuryInfo = data.BarChartSectiondataInfo;
  const hyperLinks = data.hyperlinks;

  const handleClickLink = url => {
    //google analytics custom events:
    ReactGA.event({
      category: "External link",
      action: `clicked on external link in ${injury} section: ${url}`
    });
  };

  const renderLinks = link =>
    link[injury].map(([name, url]) => (
      <ul
        className="links-list"
        style={{
          listStyleType: "none"
        }}
      >
        <li className="target-links">
          <a
            className="info-link"
            target="_blank"
            onClick={handleClickLink(url)}
            rel="noopener noreferrer"
            href={url}
            style={{
              color: CHARTCOLORSBAR[injury][0],
              fontSize: "0.9vw"
            }}
          >
            {name}
          </a>
        </li>
      </ul>
    ));

  const handleAccordion = event => {
    //toggle arrow on click
    const accordionHeader = event.target;
    accordionHeader.classList.toggle("unfolded");
  };

  useEffect(() => {
    //on click event for injury dividers
    const injuries = document.getElementsByClassName("divider");

    const onClick = event => {
      let injuryClicked = event.target.getAttribute("data-divider");
      setInjury(injuryClicked);
      //google analytics custom events:
      ReactGA.event({
        category: "Chart interaction",
        action: `click on ${injuryClicked} injury divider`
      });
    };
    Array.from(injuries).forEach(function(injury) {
      injury.addEventListener("click", onClick);
    });
  }, [injury]);

  return (
    <div className="injury-part-2 ">
      <div className="container-dividers">
        {INJURIES.map(function(injury) {
          return (
            <div
              className={"divider " + injury}
              key={injury}
              data-divider={injury}
              style={{ backgroundColor: CHARTCOLORSBAR[injury][0] }}
            >
              {injury.toUpperCase()}
            </div>
          );
        })}
      </div>
      <div
        className="background-barcharts bar-chart-fade"
        style={{
          backgroundColor: "white",
          backgroundImage: `url(/injury-svgs/${injury}.svg)`,
          backgroundRepeat: "no-repeat"
        }}
      >
        <div
          className="long-divide"
          style={{ backgroundColor: CHARTCOLORSBAR[`${injury}`][0] }}
        ></div>
        <h1 className="injury-section-2-title">{injury}</h1>
        <div className="para1">
          {id ? injuryInfo[id][injury] : injuryInfo.Canada[injury]}
        </div>
        <h1 className="bar-chart-title">
          Average Hospitalization Rate from {injury} between 2006-2012
        </h1>
        <div className="barChart-div">
          {id ? <BarChart injury={injury} /> : <BarChartHome injury={injury} />}
        </div>

        <Accordion>
          <Card>
            <Accordion.Toggle
              className="accordion-new acc-1-title"
              onClick={handleAccordion}
              as={Card.Header}
              eventKey="0"
              style={{
                backgroundColor: CHARTCOLORSBAR[injury][2],
                marginBottom: 0,
                color: "white",
                fontWeight: "bold"
              }}
            >
              Research Evidence
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Body
                style={{
                  fontSize: "0.9vw"
                }}
              >
                {injuryInfo.Research[injury]}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
        <Accordion className="acc-2">
          <Card>
            <Accordion.Toggle
              className="accordion-new acc-2-title"
              onClick={handleAccordion}
              as={Card.Header}
              eventKey="1"
              style={{
                backgroundColor: CHARTCOLORSBAR[injury][2],
                marginBottom: 0,
                color: "white",
                fontWeight: "bold"
              }}
            >
              Related Links
            </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Body>{renderLinks(hyperLinks)}</Card.Body>
            </Accordion.Collapse>
          </Card>
        </Accordion>
      </div>
    </div>
  );
}

export default withRouter(BarChartBackground);
