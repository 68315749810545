import React, { PureComponent } from "react";
import {
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  LabelList,
  ReferenceDot,
  ResponsiveContainer
} from "recharts";
import "./Timeline3.scss";
import anime from "animejs";
import data from "../../data.js";
import $ from "jquery";
import { withBreakpoints } from "react-breakpoints";
import ReactGA from "react-ga";

class Timeline extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      scrollXposition: [0],
      targetYear: 0,
      previousTargetYear: 0,
      targetPolicy: null,
      dataArray: [],
      years: []
    };
  }

  paragraphInfo = data.TimelinedataParagraph;

  normalizedPolicy = policy => {
    return policy
      .split(" ")
      .join("-")
      .replace("(", "")
      .replace(")", "")
      .replace("’", "");
  };

  selectPolicy = ({ year, policy }) => {
    const targetPolicy = this.normalizedPolicy(policy);

    // stateSet of targetYear and previousTargetYear according to data point clicked animations called and tooltip rendered
    this.setState({ previousTargetYear: this.state.targetYear }, function() {
      this.setState({ targetYear: year }, function() {
        this.setState({ targetPolicy }, function() {
          this.animeRefDot();
          this.animeLabelBounce();
        });
      });
    });
  };

  renderLabelList = props => {
    const { breakpoints, currentBreakpoint } = this.props;

    const { targetYear, dataArray, targetPolicy } = this.state;
    const { x, y, value } = props;
    const dataItem = dataArray.find(d => d.policy === value);

    let handleClickLabel = () => {
      const year = dataItem.year;
      const policy = dataItem.policy;

      this.selectPolicy({ year, policy });
      //google analytics custom event:
      ReactGA.event({
        category: "Chart interaction",
        action: `clicked on ${this.props.provinceShort} timline label: ${policy} on timeline to reveal policy`
      });
    };

    const isBounce =
      dataItem &&
      dataItem.year === targetYear &&
      this.normalizedPolicy(dataItem.policy) === targetPolicy;
    let caps = value.toUpperCase();
    let wordsMinusFirst = caps.split(" ");
    let FirstWord = wordsMinusFirst.shift();

    //set responsiveness using breakpoints
    const responsiveWidthHeight = () => {
      const { breakpoints, currentBreakpoint } = this.props;
      const breakpoint = breakpoints[currentBreakpoint];

      if (breakpoint >= breakpoints.monitorLarge) {
        return 245;
      } else if (breakpoint >= breakpoints.monitor) {
        return 215;
      } else if (breakpoint >= breakpoints.desktopLarge) {
        return 195;
      } else if (breakpoint >= breakpoints.desktopMid) {
        return 165;
      } else if (breakpoint >= breakpoints.desktop) {
        return 115;
      } else if (breakpoint >= breakpoints.tabletLandscape) {
        return 90;
      } else if (breakpoint >= breakpoints.tablet) {
        return 72;
      } else {
        return 72;
      }
    };

    const responsiveYValueLabel = () => {
      const { breakpoints, currentBreakpoint } = this.props;
      const breakpoint = breakpoints[currentBreakpoint];

      if (breakpoint >= breakpoints.monitorLarge) {
        return 370;
      } else if (breakpoint >= breakpoints.monitor) {
        return 290;
      } else if (breakpoint >= breakpoints.desktopLarge) {
        return 290;
      } else if (breakpoint >= breakpoints.desktopMid) {
        return 240;
      } else if (breakpoint >= breakpoints.desktop) {
        return 197;
      } else if (breakpoint >= breakpoints.tabletLandscape) {
        return 140;
      } else if (breakpoint >= breakpoints.tablet) {
        return 130;
      } else {
        return 130;
      }
    };

    const responsiveYText = () => {
      const { breakpoints, currentBreakpoint } = this.props;
      const breakpoint = breakpoints[currentBreakpoint];
      if (breakpoint >= breakpoints.monitorLarge) {
        return 300;
      } else if (breakpoint >= breakpoints.monitor) {
        return 240;
      } else if (breakpoint >= breakpoints.desktopLarge) {
        return 230;
      } else if (breakpoint >= breakpoints.desktopMid) {
        return 195;
      } else if (breakpoint >= breakpoints.desktop) {
        return 160;
      } else if (breakpoint >= breakpoints.tabletLandscape) {
        return 118;
      } else if (breakpoint >= breakpoints.tablet) {
        return 109;
      } else {
        return 109;
      }
    };

    const labelFontSize = () => {
      const { breakpoints, currentBreakpoint } = this.props;
      const breakpoint = breakpoints[currentBreakpoint];
      if (breakpoint >= breakpoints.monitorLarge) {
        return "1.4vw";
      } else if (breakpoint >= breakpoints.monitor) {
        return "1.4vw";
      } else if (breakpoint >= breakpoints.desktopLarge) {
        return "1.6vw";
      } else if (breakpoint >= breakpoints.desktopMid) {
        return "1.6vw";
      } else if (breakpoint >= breakpoints.desktop) {
        return "1.4vw";
      } else if (breakpoint >= breakpoints.tablet) {
        return "1.4vw";
      } else {
        return "1.4vw";
      }
    };

    let firstSpan;
    if (wordsMinusFirst.length === 1) {
      firstSpan = (
        <tspan
          x={breakpoints[currentBreakpoint] < breakpoints.tablet ? x : x + 3}
          y={
            breakpoints[currentBreakpoint] < breakpoints.desktopMid
              ? y - responsiveYText() + 7.5
              : y - responsiveYText() + 14
          }
          dominant-baseline="middle"
          text-anchor="middle"
        >
          {FirstWord}
        </tspan>
      );
    } else {
      firstSpan = (
        <tspan
          x={breakpoints[currentBreakpoint] < breakpoints.tablet ? x : x + 3}
          y={y - responsiveYText()}
          dominant-baseline="middle"
          text-anchor="middle"
        >
          {FirstWord}
        </tspan>
      );
    }

    return (
      <g>
        <rect
          className={isBounce ? "bounce move-it" : "move-it"}
          onClick={handleClickLabel}
          width={responsiveWidthHeight()}
          height={responsiveWidthHeight()}
          cursor="pointer"
          style={{
            fill: isBounce ? "#bbd431" : "#42c0ad"
          }}
          x={
            breakpoints[currentBreakpoint] < breakpoints.tablet
              ? x - responsiveWidthHeight() / 2
              : x - responsiveWidthHeight() / 2.2
          }
          y={y - responsiveYValueLabel()}
          rx={10}
          ry={10}
          scale="point" //this helps with resposiveness
        />
        <text
          fontSize={labelFontSize()}
          onClick={handleClickLabel}
          fill="#ffffff"
          y={"50%"}
          x={"50%"}
          dominantBaseline="middle"
          textAnchor="middle"
          className={
            isBounce
              ? "recharts-text recharts-label label-list bounce"
              : "recharts-text recharts-label label-list"
          }
        >
          {firstSpan}
          {wordsMinusFirst.map((word, index) => {
            return (
              <tspan
                x={
                  breakpoints[currentBreakpoint] < breakpoints.tablet
                    ? x
                    : x + 3
                }
                dy="1.3em"
                key={index}
              >
                {word}
              </tspan>
            );
          })}
        </text>
      </g>
    );
  };

  handleMerge() {
    if (this.state.previousTargetYear < this.state.targetYear) {
      return -115;
    } else {
      return 115;
    }
  }

  animeRefDot = () => {
    anime({
      targets: ".ref-dot",
      translateX: [
        {
          value: this.handleMerge(),
          duration: 50,
          easing: "easeOutExpo"
        },
        { value: 0, duration: 600 }
      ],
      opacity: [0, 1],
      duration: 1000
    });
  };

  animeLabelBounce = () => {
    anime({
      targets: ".bounce",
      translateY: [
        { value: -10, duration: 100, easing: "easeOutSine" },
        { value: 0, duration: 1000, easing: "easeOutBounce" }
      ],
      loop: false
    });
  };

  handleClickDate = props => {
    const index = props.index;
    const year = this.state.dataArray[index].year;
    const policy = this.state.dataArray[index].policy;

    this.selectPolicy({ year, policy });
    //google analytics custom event:
    ReactGA.event({
      category: "Chart interaction",
      action: `clicked on year: ${year} on timeline to reveal policy`
    });
  };

  componentDidMount() {
    //fade animation for whole component
    anime({
      targets: ".timeline-fade",
      opacity: [0, 1],
      duration: 10000,
      loop: false
    });
    //set relevant data array for province based on props
    const newDataArray = data.policyData[this.props.provinceShort];
    this.setState({ dataArray: newDataArray });

    //set years array in state for X axis
    // eslint-disable-next-line array-callback-return
    const yearsArray = newDataArray.map(d => {
      if (d.year.length < 4) {
        return d.year;
      }
    });

    this.setState({ years: yearsArray });

    $(document).on(
      "click",
      function(event) {
        if (
          !$(event.target).closest(".recharts-scatter-symbol, .clickable")
            .length
        ) {
          this.setState({ targetPolicy: null }, function() {
            const dateLabel = document.getElementsByClassName("date-label");
            for (var i = 0; i < dateLabel.length; i++) {
              dateLabel[i].style.fill = "#42c0ad";
              dateLabel[i].style.stroke = "#42c0ad";
            }
          });
        }
      }.bind(this)
    );
  }

  componentDidUpdate(oldProps) {
    const newProps = this.props;

    if (oldProps.provinceShort !== newProps.provinceShort) {
      //set relevant data array for province based on props
      const newDataArray = data.policyData[this.props.provinceShort];
      this.setState({ dataArray: newDataArray }, function() {});

      //set years array in state for X axis
      const yearsArray = newDataArray.map(d => {
        return d.year;
      });

      this.setState({ years: yearsArray });
    }
    anime({
      targets: ".background-fade",
      opacity: [0, 1],
      duration: 20000,
      loop: false
    });
  }

  renderTableData = targetPolicy => {
    const dataRef = targetPolicy.split("-").join("");

    return data.TimelinePopUpInfo[this.props.provinceShort][dataRef].map(
      (policy, index) => {
        return (
          <tr key={index}>
            <td className="timeline-table-left-header">{policy[0]}</td>
            <td className="timeline-table-body">{policy[1]}</td>
            <td className="timeline-table-body">{policy[2]}</td>
          </tr>
        );
      }
    );
  };

  responsiveAspect = () => {
    const { breakpoints, currentBreakpoint } = this.props;
    const breakpoint = breakpoints[currentBreakpoint];

    if (breakpoint >= breakpoints.monitorLarge) {
      return 1.9 / 1;
    } else if (breakpoint >= breakpoints.monitor) {
      return 1.6 / 0.6;
    } else if (breakpoint >= breakpoints.desktopLarge) {
      return 1.6 / 0.7;
    } else if (breakpoint >= breakpoints.desktopMid) {
      return 1.6 / 0.9;
    } else if (breakpoint >= breakpoints.desktop) {
      return 1.3 / 1;
    } else if (breakpoint >= breakpoints.tablet) {
      return 1.3 / 1.2;
    } else {
      return 1 / 1.2;
    }
  };

  render() {
    const CustomizedTick = ({ x, y, payload }) => {
      const responsiveDateYValue = () => {
        const { breakpoints, currentBreakpoint } = this.props;
        const breakpoint = breakpoints[currentBreakpoint];

        if (breakpoint >= breakpoints.monitorLarge) {
          return 540;
        } else if (breakpoint >= breakpoints.monitor) {
          return 470;
        } else if (breakpoint >= breakpoints.desktopLarge) {
          return 460;
        } else if (breakpoint >= breakpoints.desktopMid) {
          return 410;
        } else if (breakpoint >= breakpoints.desktop) {
          return 355;
        } else if (breakpoint >= breakpoints.tabletLandscape) {
          return 292.5;
        } else if (breakpoint >= breakpoints.tablet) {
          return 278;
        } else {
          return 278;
        }
      };
      const displayYear = String(payload.value).substring(0, 4);

      return (
        <g cursor="pointer" className="clickable">
          <text
            cursor="pointer"
            className="clickable date-label"
            // x={displayYear === lastYear ? x + 8 : x}
            x={x}
            // paddingLeft={displayYear === lastYear ? 8 : 0}
            // transform={
            //   displayYear === lastYear ? 'translate(10, 0)' : 'translate(0, 0)'
            // }
            y={y - responsiveDateYValue()}
            textAnchor="middle"
            fill={
              payload.value === this.state.targetYear ? "#bbd431" : "#42c0ad"
            }
            strokeWidth={"0.14vw"}
            stroke={
              payload.value === this.state.targetYear ? "#bbd431" : "#42c0ad"
            }
            fontFamily="oswald"
            letterSpacing={"0.14vw"}
            fontSize={"1.6vw"}
          >
            {displayYear}
          </text>
        </g>
      );
    };

    return (
      <div className="timeline-container">
        <div className="title-timeline">POLICIES</div>
        <p className="timeline-para">
          {this.paragraphInfo[this.props.provinceShort]}
        </p>
        <ResponsiveContainer
          className="scatter-chart fade-in timeline-fade"
          aspect={this.responsiveAspect()}
        >
          <ScatterChart>
            <XAxis
              onClick={this.handleClickDate}
              dataKey="year"
              name="year"
              strokeWidth={"0.3vw"}
              stroke="#42c0ad"
              style={{ fontSize: "1.5vw" }}
              tick={<CustomizedTick />}
              tickLine={{
                transform: "translate(0, -130)",
                stroke: "#42c0ad",
                strokeWidth: 3
              }}
              tickSize={123}
              scale="point" //this helps with resposiveness
              domain={[
                Math.min(...this.state.years),
                Math.max(...this.state.years)
              ]}
            />
            <YAxis
              className=""
              type="number"
              dataKey="Yindex"
              tick={false}
              tickLine={false}
              axisLine={false}
            />
            <Scatter
              className="scatter-point clickable"
              data={this.state.dataArray}
              fill="#42c0ad"
              stroke="#42c0ad"
              strokeWidth={"0.7vw"}
              animationDuration={1500}
            >
              <LabelList
                scale="point"
                content={this.renderLabelList}
                preserveAspectRatio="none"
                dataKey="policy"
                fontSize={"1.4vw"}
                position="top"
                border="solid"
                fontFamily="oswald"
              />
            </Scatter>
            <ReferenceDot
              className="ref-dot"
              fill="#c9e204"
              stroke="#c9e204"
              strokeWidth={
                this.props.breakpoints[this.props.currentBreakpoint] >
                this.props.breakpoints.tabletLandscape
                  ? "0.8vw"
                  : "0.2vw"
              }
              x={this.state.targetYear}
              y={0}
              style={{
                width: 0,
                height: 0
              }}
            />
          </ScatterChart>
        </ResponsiveContainer>

        {this.state.targetPolicy && (
          <div
            className="timeline-pop-up-container"
            style={{
              backgroundColor: "#bbd431"
            }}
          >
            <div
              className="icon-pop-up background-fade"
              style={{
                backgroundImage: `url(/policy-score-card-tab/${this.state.targetPolicy
                  .split("-")[0]
                  .toLowerCase()}.svg)`,
                backgroundRepeat: "no-repeat",
                opacity: 0
              }}
            ></div>

            <table className="timeline-table">
              <tr>
                <th></th>
                <th className="timeline-table-top-header">
                  Best Evidence Based Policy
                </th>
                <th className="timeline-table-top-header">
                  {this.props.provinceLong}'s Policy
                </th>
              </tr>
              {this.renderTableData(this.state.targetPolicy)}
            </table>
          </div>
        )}
      </div>
    );
  }
}

export default withBreakpoints(Timeline);
