import React, { useEffect } from "react";
import "./ProvincePage.scss";
import Timeline3 from "../Timeline3";
import LineChartBackground from "../LineChartBackground";
import BarChartBackground from "../BarChartBackground";
import ProvinceIntro from "../ProvinceIntro";
import ScoreCard from "../ScoreCard";
import InfoProvince from "../InfographicProvince";
import { withBreakpoints } from "react-breakpoints";
import MobileView from "../MobileView";
import Appbar from "../Appbar";
import { PROVINCES } from "../../constants";
import Footer from "../Footer";

function ScrollToTopOnMount() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return null;
}

function ProvincePage({ match, currentBreakpoint, breakpoints }) {
  const { id } = match.params;

  return breakpoints[currentBreakpoint] > breakpoints.mobileLandscape ? (
    <div>
      <ScrollToTopOnMount />
      <Appbar />
      <ProvinceIntro provinceShort={id} provinceLong={PROVINCES[id]} />
      <Timeline3
        id="Timeline3"
        provinceShort={id}
        provinceLong={PROVINCES[id]}
      />
      <InfoProvince provinceShort={id} provinceLong={PROVINCES[id]} />
      <LineChartBackground provinceShort={id} provinceLong={PROVINCES[id]} />
      <BarChartBackground provinceShort={id} provinceLong={PROVINCES[id]} />
      <ScoreCard provinceShort={id} provinceLong={PROVINCES[id]} />
      <Footer />
    </div>
  ) : (
    <MobileView />
  );
}

export default withBreakpoints(ProvincePage);
