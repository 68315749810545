import React, { useEffect, useState } from 'react';
import './PolicyTableBody.scss';
import { withRouter } from 'react-router';

function PolicyTableBody({ match }) {
  const { id } = match.params;
  const [highlightArray, setHighlightArray] = useState(null);

  useEffect(() => {
    if (highlightArray) {
      for (var i = 0; i < highlightArray.length; i++) {
        highlightArray[i].classList.remove('highlight');
      }
    }
    let highlight = document.getElementsByClassName(id);

    setHighlightArray(highlight);
  }, [id, highlightArray]);

  useEffect(() => {
    if (highlightArray) {
      for (var i = 0; i < highlightArray.length; i++) {
        highlightArray[i].classList.add('highlight');
      }
    }
  }, [highlightArray]);

  return (
    <React.Fragment>
      <tr>
        <th className='mid province-header ON ' data-province='ON'>
          ONTARIO
        </th>
        <td className='ON td-policy'>2</td>
        <td className='ON td-policy'>3</td>
        <td className='ON td-policy'>3</td>
        <td className='ON td-policy'>1</td>
        <td className='ON td-policy'>3</td>
        <td className='ON td-policy'>2</td>
        <td className='total-data ON'>14</td>
        <td className='total-data ON'>1</td>
      </tr>
      <tr>
        <th className='province-header BC pale' data-province='BC'>
          BRITISH COLUMBIA
        </th>
        <td className='BC'>1</td>
        <td className='BC'>1</td>
        <td className='BC'>3</td>
        <td className='BC'>3</td>
        <td className='BC'>3</td>
        <td className='BC'>2</td>
        <td className='total-data BC'>12</td>
        <td className='total-data BC'>2</td>
      </tr>
      <tr>
        <th className='province-header  NS mid' data-province='NS'>
          NOVA SCOTIA
        </th>
        <td className='NS'>1</td>
        <td className='NS'>2</td>
        <td className='NS'>2</td>
        <td className='NS'>3</td>
        <td className='NS'>3</td>
        <td className='NS'>1</td>
        <td className='total-data NS'>12</td>
        <td className='total-data NS'>3</td>
      </tr>
      <tr>
        <th className='province-header PEI pale' data-province='PEI'>
          PRINCE EDWARD ISLAND
        </th>
        <td className='PEI'>1</td>
        <td className='PEI'>1</td>
        <td className='PEI'>3</td>
        <td className='PEI'>2</td>
        <td className='PEI'>3</td>
        <td className='PEI'>2</td>
        <td className='total-data PEI'>12</td>
        <td className='total-data PEI'>3</td>
      </tr>
      <tr>
        <th className='province-header MB mid' data-province='MB'>
          MANITOBA
        </th>
        <td className='MB'>3</td>
        <td className='MB'>1</td>
        <td className='MB'>2</td>
        <td className='MB'>1</td>
        <td className='MB'>2</td>
        <td className='MB'>1</td>
        <td className='total-data MB'>10</td>
        <td className='total-data MB'>5</td>
      </tr>
      <tr>
        <th className='province-header NB pale' data-province='NB'>
          NEW BRUNSWICK
        </th>
        <td className='NB'>0</td>
        <td className='NB'>2</td>
        <td className='NB'>2</td>
        <td className='NB'>2</td>
        <td className='NB'>2</td>
        <td className='NB'>2</td>
        <td className='total-data NB'>10</td>
        <td className='total-data NB'>5</td>
      </tr>
      <tr>
        <th className='province-header NL mid' data-province='NL'>
          NEWFOUNDLAND & LABRADOR
        </th>
        <td className='NL'>1</td>
        <td className='NL'>1</td>
        <td className='NL'>2</td>
        <td className='NL'>2</td>
        <td className='NL'>1</td>
        <td className='NL'>2</td>
        <td className='total-data NL'>9</td>
        <td className='total-data NL'>7</td>
      </tr>
      <tr>
        <th className='province-header AB pale' data-province='AB'>
          ALBERTA
        </th>
        <td className='AB'>2</td>
        <td className='AB'>1</td>
        <td className='AB'>2</td>
        <td className='AB'>1</td>
        <td className='AB'>0</td>
        <td className='AB'>2</td>
        <td className='total-data AB'>8</td>
        <td className='total-data AB'>8</td>
      </tr>
      <tr>
        <th className='province-header SK mid' data-province='SK'>
          SASKATCHEWAN
        </th>
        <td className='SK'>0</td>
        <td className='SK'>0</td>
        <td className='SK'>2</td>
        <td className='SK'>0</td>
        <td className='SK'>2</td>
        <td className='SK'>1</td>
        <td className='total-data SK'>5</td>
        <td className='total-data SK'>9</td>
      </tr>
    </React.Fragment>
  );
}

export default withRouter(PolicyTableBody);
