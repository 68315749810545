import React from "react";
import "./App.scss";
import HomePage from "./components/HomePage";
import ProvincePage from "./components/ProvincePage";
import AboutPage from "./components/AboutPage";
import PrivacyPolicyPage from "./components/PrivacyPolicyPage";
import { Route, Redirect, Switch } from "react-router-dom";
import ReactBreakpoints from "react-breakpoints";

//google analytics
import ReactGA from "react-ga";
ReactGA.initialize("UA-56172012-6");

function fireTracking() {
  ReactGA.pageview(window.location.hash);
}

//responsive breakpoints
const breakpoints = {
  mobile: 320,
  mobileLandscape: 576,
  tablet: 768,
  desktop: 992,
  tabletLandscape: 1025,
  desktopMid: 1350,
  desktopLarge: 1680,
  monitor: 2200,
  monitorLarge: 2560
};

function App() {
  return (
    <ReactBreakpoints
      breakpoints={breakpoints}
      debounceResize={true}
      debounceDelay={100}
    >
      <div className="App">
        <Switch>
          <Route exact path="/" component={HomePage} onUpdate={fireTracking} />
          <Route
            exact
            path="/about"
            component={AboutPage}
            onUpdate={fireTracking}
          />
          <Route
            exact
            path="/privacy"
            component={PrivacyPolicyPage}
            onUpdate={fireTracking}
          />
          <Route
            exact
            path="/provinces/:id"
            component={ProvincePage}
            onUpdate={fireTracking}
          />
          <Redirect to="/" />
        </Switch>
      </div>
    </ReactBreakpoints>
  );
}

export default App;
