export const PROVINCES = {
  AB: "Alberta",
  BC: "British Columbia",
  MB: "Manitoba",
  NB: "New Brunswick",
  NL: "Newfoundland & Labrador",
  NS: "Nova Scotia",
  ON: "Ontario",
  PEI: "Prince Edward Island",
  SK: "Saskatchewan"
};

export const INJURIES = [
  "Falls",
  "Transport",
  "Cyclist",
  "Occupant",
  "Poisoning",
  "Pedestrian",
  "Burns",
  "Choking",
  "Drowning"
];

export const CHARTCOLORSBAR = {
  Falls: ["#3E7CB1", "#729FC6", "#A7C3DB"],
  Transport: ["#5FAD56", "#7CBB74", "#A7D2A2"],
  Cyclist: ["#008148", "#45A379", "#8BC5AB"],
  Occupant: ["#F9BE02", "#FACF47", "#FCE18C"],
  Poisoning: ["#F18F01", "#F3A32F", "#F6B75D"],
  Pedestrian: ["#FF6B35", "#FF7847", "#FF936C"],
  Burns: ["#EA3B24", "#ED5E4B", "#F5A59B"],
  Choking: ["#CE1483", "#D63E99", "#E894C6"],
  Drowning: ["#9D44B5", "#B777C9", "#DBBBE4"],
  AV: ["#A7C3DB", "#729FC6", "#A7C3DB"]
};

export const CHARTCOLORSLINE = {
  Falls: "#3E7CB1",
  Transport: "#5FAD56",
  Cyclist: "#008148",
  Occupant: "#F9BE02",
  Poisoning: "#F18F01",
  Pedestrian: "#FF6B35",
  Burns: "#EA3B24",
  Choking: "#CE1483",
  Drowning: "#9D44B5"
};

//ranking for score cards with layout: [PROVINCE: ['Overall', 'Policy']]
export const RANKING = {
  AB: ["3", "8"],
  BC: ["1", "2"],
  ON: ["2", "1"],
  MB: ["5", "5"],
  NB: ["8", "5"],
  NL: ["7", "7"],
  NS: ["3", "3"],
  PEI: ["5", "3"],
  SK: ["9", "9"]
};

export const RESPONSIVEXVALHRECT = {
  dataPoints_2: ["1.3", "0.048"],
  dataPoints_3: ["1.3", "0.088", "0.048"],
  dataPoints_4: ["1.3", "0.138", "0.068", "0.048"],
  dataPoints_5: ["1.3", "0.158", "0.082", "0.058", "0.043"]
};

export const RESPONSIVEXVALEXT1 = {
  dataPoints_2: ["0", "0"],
  dataPoints_3: ["0", "0", "0"],
  dataPoints_4: ["0", "0", "0", "0"],
  dataPoints_5: ["0", "0", "0", "0", "0"]
};

export const RESPONSIVEWIDTHRECT = {
  dataPoints_2: ["0.168", "0.058"],
  dataPoints_3: ["0.238", "0.088", "0.058"],
  dataPoints_4: ["0.268", "0.108", "0.068", "0.048"],
  dataPoints_5: ["0.28", "0.128", "0.082", "0.062", "0.047"]
};
