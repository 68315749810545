import React, { Component } from "react";
import {
  LineChart,
  Line,
  Tooltip,
  Legend,
  XAxis,
  YAxis,
  Label,
  Surface,
  Symbols,
  ResponsiveContainer
} from "recharts";
import "./LineChart.scss";
import anime from "animejs";
import _ from "lodash";
import data from "../../data.js";
import { CHARTCOLORSLINE } from "../../constants";
import TrackVisibility from "react-on-screen";
import { withBreakpoints } from "react-breakpoints";
import ReactGA from "react-ga";

class LineGraph extends Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: [],
      empty: [
        "Falls",
        "Transport",
        "Pedestrian",
        "Burns",
        "Poisoning",
        "Occupant",
        "Cyclist",
        "Choking",
        "Drowning"
      ]
    };
  }

  handleClick = dataKey => {
    const { disabled } = this.state;

    if (_.includes(disabled, dataKey)) {
      this.setState({
        disabled: disabled.filter(obj => obj !== dataKey)
      });
    } else {
      this.setState({ disabled: disabled.concat(dataKey) });
    }

    const nonDisabled = this.state.empty.filter(f => !disabled.includes(f));
    //google analytics custom events:
    ReactGA.event({
      category: "Chart interaction",
      action: `click on ${this.props.provinceLong} line chart legend to show following injuries: ${nonDisabled}`
    });
  };

  renderCusomizedLegend = ({ payload }) => {
    const { breakpoints, currentBreakpoint } = this.props;
    return (
      <div className="customized-legend">
        {payload.map(entry => {
          const { dataKey, color } = entry;
          const active = _.includes(this.state.disabled, dataKey);
          const style = {
            color: active ? "#9fa0a3" : "#57585b"
          };

          return (
            <div
              key={dataKey}
              className="legend-item"
              onClick={() => this.handleClick(dataKey)}
              style={style}
            >
              <Surface width="1.3vw" height="0.8vw">
                <Symbols
                  cx={
                    breakpoints[currentBreakpoint] > breakpoints.tabletLandscape
                      ? 9
                      : 3
                  }
                  cy={
                    breakpoints[currentBreakpoint] > breakpoints.tabletLandscape
                      ? 6
                      : 3
                  }
                  size={
                    breakpoints[currentBreakpoint] > breakpoints.tabletLandscape
                      ? 150
                      : 50
                  }
                  fill={color}
                  r={7}
                />
                {active && (
                  <Symbols
                    cx={
                      breakpoints[currentBreakpoint] >
                      breakpoints.tabletLandscape
                        ? 9
                        : 3
                    }
                    cy={
                      breakpoints[currentBreakpoint] >
                      breakpoints.tabletLandscape
                        ? 6
                        : 3
                    }
                    size={
                      breakpoints[currentBreakpoint] >
                      breakpoints.tabletLandscape
                        ? 50
                        : 10
                    }
                    fill={"#FFF"}
                  />
                )}
              </Surface>
              <span>{dataKey}</span>
            </div>
          );
        })}
      </div>
    );
  };

  googleClick() {
    ReactGA.event({
      category: "Chart interaction",
      action: "click on line chart legend"
    });
  }

  componentDidMount() {
    const allSymbols = document.getElementsByClassName("recharts-symbols");

    Array.from(allSymbols).forEach(function(symbol) {
      symbol.classList.add("responsive-circle");
    });
    anime({
      targets: ".fade-in",
      opacity: [0, 1],
      duration: 10000,
      loop: false
    });
  }
  componentDidUpdate() {
    anime({
      targets: ".fade-in",
      opacity: [0, 1],
      duration: 10000,
      loop: false
    });
  }

  responsiveHeight = () => {
    return "20vw";
  };

  render() {
    const { breakpoints, currentBreakpoint } = this.props;
    return (
      <div className="bc-hospitalization-graph">
        <h3 className="title-hospitalization-graph">
          Population Based Injury Hospitalization Rate in{" "}
          {this.props.provinceLong} (0-19 years)
        </h3>
        <TrackVisibility partialVisibility>
          {({ isVisible }) =>
            isVisible && (
              <ResponsiveContainer
                className="responsive-line-chart-container"
                aspect={2.2 / 0.9}
              >
                <LineChart
                  className="line-chart "
                  data={data.dataLineGraph[this.props.provinceShort]}
                  margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                >
                  {_.toPairs(CHARTCOLORSLINE)
                    .filter(pair => !_.includes(this.state.disabled, pair[0]))
                    .map(pair => (
                      <Line
                        // key={pair[0]} this key is intentionally not passed to force a re-render so that all chart lines render together for animation purposes.
                        dataKey={pair[0]}
                        animationDuration={3000}
                        activeDot={{ r: 7 }}
                        type="monotone"
                        stroke={pair[1]}
                      />
                    ))}

                  <Legend
                    iconType="circle"
                    verticalAlign="right"
                    align="left"
                    onClick={this.googleClick}
                    wrapperStyle={{
                      top: "20%",
                      right: "5%",
                      lineHeight: "180%",
                      left: "93%",
                      width: "70%",
                      fontSize: "0.8vw",
                      height: "40%"
                    }}
                    payload={_.toPairs(CHARTCOLORSLINE).map(pair => ({
                      dataKey: pair[0],
                      color: pair[1]
                    }))}
                    content={this.renderCusomizedLegend}
                  />
                  <XAxis
                    dataKey="name"
                    animationDuration={100}
                    fontSize="0.8vw"
                  >
                    <Label
                      className="label x-axis"
                      value="YEAR"
                      position="bottom"
                      fontSize="1.2vw"
                      dy={20}
                    />
                  </XAxis>
                  <YAxis fontSize="0.8vw">
                    <Label
                      dx={
                        breakpoints[currentBreakpoint] >
                        breakpoints.tabletLandscape
                          ? -40
                          : -10
                      }
                      className="label y-axis"
                      value="HOSPITALIZATION RATE PER 100,000"
                      angle={-90}
                      position="leftMiddle"
                      fontSize="1.2vw"
                    />
                  </YAxis>
                  <Tooltip
                    cursor={{ strokeDasharray: "3 3" }}
                    isAnimationActive={false}
                    contentStyle={{ textAlign: "left", fontSize: "1vw" }}
                    labelStyle={{
                      fontSize: "1vw",
                      textAlign: "center",
                      marginBottom: 5,
                      fontWeight: "bold"
                    }}
                    formatter={(value, name, _props) => [value, `● ${name}`]}
                  />
                </LineChart>
              </ResponsiveContainer>
            )
          }
        </TrackVisibility>
      </div>
    );
  }
}

export default withBreakpoints(LineGraph);
