import React from 'react';

function PolicyHeader() {
  return (
    <React.Fragment>
      <th className='table-header'>
        SMOKE & CO <br /> DETECTOR SCORE
      </th>
      <th className='table-header'>
        PEDESTRIAN SAFETY <br /> SCORE
      </th>
      <th className='table-header'>
        DISTRACTED DRIVING <br /> SCORE
      </th>
      <th className='table-header'>
        BICYCLE HELMET <br />
        SCORE
      </th>
      <th className='table-header'>
        BOOSTER SEAT <br />
        SCORE
      </th>
      <th className='table-header'>
        GRADUATED DRIVER’S <br /> LICENSING SCORE
      </th>
      <th className='total-header'>
        OVERALL <br /> SCORE
      </th>
      <th className='total-header'>PLACE</th>
    </React.Fragment>
  );
}

export default PolicyHeader;
