import React from "react";
import "./InfographicProvince.scss";
import { withRouter } from "react-router";

function InfoProvince({ match }) {
  const { id } = match.params;

  return (
    <div className="info-container">
      <div
        className="infrographic-section"
        style={{
          backgroundImage: `url(/infographics/${id}1.svg)`,
          backgroundSize: "cover"
        }}
      ></div>
    </div>
  );
}

export default withRouter(InfoProvince);
