import React, { useState, useEffect } from 'react';
import Map from '../Map';
import HomepageIntro from '../HomepageIntro';
import LineChartBackground from '../LineChartBackground';
import BarChartBackground from '../BarChartBackground';
import ScoreCard from '../ScoreCard';
import { withBreakpoints } from 'react-breakpoints';
import MobileView from '../MobileView';
import Appbar from '../Appbar';
import Footer from '../Footer';

//change state if map component is in view and pass this state to appbar component

function HomePage({ currentBreakpoint, breakpoints }) {
  const [mapInViewPort, setmapInViewPort] = useState(true);

  const isInViewport = function(elem) {
    const navBarComponent = document.getElementById('basic-navbar-nav');
    const bounding = elem.getBoundingClientRect();
    return (
      bounding.top + bounding.height - navBarComponent.offsetHeight >= 0 &&
      bounding.bottom <=
        (window.innerHeight || document.documentElement.clientHeight)
    );
  };

  useEffect(() => {
    //IS ARROW IN VIEWPORT (logo in app bar will appear if map component not in view)?
    window.addEventListener('scroll', function(event) {
      const topMapComponent = document.querySelector('.map-paragraph');

      if (topMapComponent) {
        if (isInViewport(topMapComponent)) {
          setmapInViewPort(true);
        } else {
          setmapInViewPort(false);
        }
      }
    });
  });

  return breakpoints[currentBreakpoint] > breakpoints.mobileLandscape ? (
    <React.Fragment>
      <Appbar logoIsVisible={mapInViewPort} />
      <Map />
      <HomepageIntro />
      <LineChartBackground />
      <BarChartBackground />
      <ScoreCard />
      <Footer />
    </React.Fragment>
  ) : (
    <MobileView />
  );
}

export default withBreakpoints(HomePage);
