import React, { useEffect } from "react";
import "./ProvinceIntro.scss";
import { withRouter } from "react-router";
import { RANKING } from "../../constants";
import provinceIntroPara from "../../data.js";
import { withBreakpoints } from "react-breakpoints";

function ProvinceIntro({
  match,
  provinceLong,
  currentBreakpoint,
  breakpoints
}) {
  const { id } = match.params;

  useEffect(() => {
    //set alt-text on province titles
    const provTitles = document.getElementsByClassName("prov-title")[0];
    const altNeeded = `reportcard for ${provinceLong}`;
    provTitles.setAttribute("alt", altNeeded);

    //set alt-text on province images
    const provImages = document.getElementsByClassName("province-svg")[0];
    const altImage = `#${RANKING[id][0]} OVERALL and #${RANKING[id][1]} POLICY`;
    provImages.setAttribute("alt", altImage);
  });
  return (
    <div
      className="outer-container"
      style={{
        backgroundImage: `url(/province-svgs/${id}-1.jpg)`,
        backgroundRepeat: "none",
        backgroundSize: "cover"
      }}
    >
      <div className="province-svg"></div>

      <div className="prov-title"></div>

      <p
        className="intro-para"
        style={{
          marginTop:
            breakpoints[currentBreakpoint] > breakpoints.tablet
              ? (id === "NL") | (id === "PEI")
                ? "6vw"
                : " -5vw"
              : (id === "NL") | (id === "PEI")
              ? "7vw"
              : " -3vw"
        }}
      >
        {provinceIntroPara.provinceIntroPara[id]}
      </p>
    </div>
  );
}

export default withBreakpoints(withRouter(ProvinceIntro));
