//data for hospitalization each year (from 2006-2012) by injury per province.
//this data is shown in the linegraphs.
const dataLineGraph = {
  BC: [
    {
      name: "2006",
      Total: 667.55,
      Falls: 144.93,
      Transport: 103.08,
      Cyclist: 34.22,
      Occupant: 29.48,
      Poisoning: 20.0,
      Pedestrian: 12.37,
      Burns: 8.56,
      Choking: 3.4,
      Drowning: 2.06
    },
    {
      name: "2007",
      Total: 636.66,
      Falls: 130.74,
      Transport: 99.76,
      Cyclist: 31.91,
      Occupant: 28.81,
      Poisoning: 20.86,
      Pedestrian: 13.12,
      Burns: 8.06,
      Choking: 3.72,
      Drowning: 1.55
    },
    {
      name: "2008",
      Total: 583.96,
      Falls: 127.13,
      Transport: 80.82,
      Cyclist: 20.98,
      Occupant: 24.91,
      Poisoning: 18.6,
      Pedestrian: 9.41,
      Burns: 8.89,
      Choking: 3.51,
      Drowning: 2.07
    },
    {
      name: "2009",
      Total: 574.3,
      Falls: 126.38,
      Transport: 81.08,
      Cyclist: 24.3,
      Occupant: 20.99,
      Poisoning: 19.13,
      Pedestrian: 9.31,
      Burns: 6.93,
      Choking: 4.34,
      Drowning: 1.55
    },
    {
      name: "2010",
      Total: 547.24,
      Falls: 116.56,
      Transport: 71.37,
      Cyclist: 22.24,
      Occupant: 19.55,
      Poisoning: 17.58,
      Pedestrian: 8.69,
      Burns: 6.62,
      Choking: 1.65,
      Drowning: 1.65
    },
    {
      name: "2011",
      Total: 537.95,
      Falls: 118.81,
      Transport: 68.82,
      Cyclist: 20.39,
      Occupant: 19.35,
      Poisoning: 20.28,
      Pedestrian: 9.42,
      Burns: 7.45,
      Choking: 4.04,
      Drowning: 1.03
    },
    {
      name: "2012",
      Total: 515.72,
      Falls: 114.05,
      Transport: 60.76,
      Cyclist: 19.8,
      Occupant: 12.55,
      Poisoning: 17.21,
      Pedestrian: 8.09,
      Burns: 4.15,
      Choking: 3.84,
      Drowning: 0.83
    }
  ],
  AB: [
    {
      name: 2006,
      Total: 687.21,
      Falls: 131.34,
      Transport: 114.57,
      Occupant: 41.69,
      Cyclist: 18.44,
      Pedestrian: 9.84,
      Poisoning: 20.46,
      Burns: 7.04,
      Choking: 6.15,
      Drowning: 1.45
    },
    {
      name: 2007,
      Total: 687.04,
      Falls: 146.1,
      Transport: 109.27,
      Occupant: 36.94,
      Cyclist: 21.12,
      Pedestrian: 8.07,
      Poisoning: 23.34,
      Burns: 7.3,
      Choking: 5.31,
      Drowning: 1
    },
    {
      name: 2008,
      Total: 665.51,
      Falls: 128.42,
      Transport: 105.47,
      Occupant: 33.12,
      Cyclist: 22.62,
      Pedestrian: 6.89,
      Poisoning: 20.77,
      Burns: 8.53,
      Choking: 5.9,
      Drowning: 1.42
    },
    {
      name: 2009,
      Total: 673.18,
      Falls: 135,
      Transport: 98.58,
      Occupant: 28.21,
      Cyclist: 20.86,
      Pedestrian: 8.97,
      Poisoning: 17.94,
      Burns: 9.4,
      Choking: 5.51,
      Drowning: 1.95
    },
    {
      name: 2010,
      Total: 677.59,
      Falls: 129.94,
      Transport: 87.23,
      Occupant: 27.79,
      Cyclist: 17.7,
      Pedestrian: 6.87,
      Poisoning: 19.31,
      Burns: 8.26,
      Choking: 3.43,
      Drowning: 1.07
    },
    {
      name: 2011,
      Total: 677.1,
      Falls: 130.11,
      Transport: 86.71,
      Occupant: 24.83,
      Cyclist: 18.78,
      Pedestrian: 9.13,
      Poisoning: 20.06,
      Burns: 7.32,
      Choking: 4.56,
      Drowning: 1.91
    },
    {
      name: 2012,
      Total: 679.99,
      Falls: 126.12,
      Transport: 83.42,
      Occupant: 27.67,
      Cyclist: 17.96,
      Pedestrian: 5.64,
      Poisoning: 17.96,
      Burns: 7,
      Choking: 5.01,
      Drowning: 1.67
    }
  ],
  MB: [
    {
      name: 2006,
      Total: 607.32,
      Falls: 105.76,
      Transport: 118.11,
      Occupant: 42.43,
      Cyclist: 21.53,
      Pedestrian: 13.62,
      Poisoning: 17.42,
      Burns: 11.4,
      Choking: 3.17,
      Drowning: 1.9
    },
    {
      name: 2007,
      Total: 627.39,
      Falls: 101.71,
      Transport: 95.69,
      Occupant: 32.95,
      Cyclist: 17.74,
      Pedestrian: 12.04,
      Poisoning: 18.69,
      Burns: 13.63,
      Choking: 3.17,
      Drowning: 0.95
    },
    {
      name: 2008,
      Total: 623.5,
      Falls: 126.09,
      Transport: 93.22,
      Occupant: 30.65,
      Cyclist: 13.27,
      Pedestrian: 12.01,
      Poisoning: 18.96,
      Burns: 11.38,
      Choking: 3.48,
      Drowning: 3.16
    },
    {
      name: 2009,
      Total: 578.87,
      Falls: 91.51,
      Transport: 84.9,
      Occupant: 33.64,
      Cyclist: 14.46,
      Pedestrian: 10.38,
      Poisoning: 18.87,
      Burns: 13.84,
      Choking: 2.52,
      Drowning: 1.89
    },
    {
      name: 2010,
      Total: 581.64,
      Falls: 87.03,
      Transport: 87.03,
      Occupant: 33.81,
      Cyclist: 15.03,
      Pedestrian: 7.51,
      Poisoning: 15.34,
      Burns: 9.7,
      Choking: 3.13,
      Drowning: 1.25
    },
    {
      name: 2011,
      Total: 593.56,
      Falls: 102.77,
      Transport: 83.77,
      Occupant: 27.72,
      Cyclist: 14.64,
      Pedestrian: 9.03,
      Poisoning: 18.06,
      Burns: 5.92,
      Choking: 3.43,
      Drowning: 1.25
    },
    {
      name: 2012,
      Total: 555.7,
      Falls: 89.42,
      Transport: 67.45,
      Occupant: 21.66,
      Cyclist: 12.07,
      Pedestrian: 12.38,
      Poisoning: 20.42,
      Burns: 8.97,
      Choking: 3.71,
      Drowning: 1.86
    }
  ],
  NB: [
    {
      name: 2006,
      Total: 774.55,
      Falls: 173.51,
      Transport: 153.24,
      Occupant: 47.7,
      Cyclist: 44.72,
      Pedestrian: 4.77,
      Poisoning: 29.81,
      Burns: "7.75",
      Choking: 0.6,
      Drowning: 0
    },
    {
      name: 2007,
      Total: 741.93,
      Falls: 190.61,
      Transport: 115.21,
      Occupant: 39.81,
      Cyclist: 32.57,
      Pedestrian: 4.22,
      Poisoning: 25.93,
      Burns: "9.65",
      Choking: 3.62,
      Drowning: 1.2
    },
    {
      name: 2008,
      Total: 729.07,
      Falls: 164.59,
      Transport: 104.85,
      Occupant: 29.26,
      Cyclist: 32.92,
      Pedestrian: 6.71,
      Poisoning: 27.43,
      Burns: "10.36",
      Choking: 2.44,
      Drowning: 0
    },
    {
      name: 2009,
      Total: 700.75,
      Falls: 162.89,
      Transport: 103.27,
      Occupant: 36.27,
      Cyclist: 30.12,
      Pedestrian: 8.61,
      Poisoning: 30.12,
      Burns: "12.91",
      Choking: 3.07,
      Drowning: 0.61
    },
    {
      name: 2010,
      Total: 717.48,
      Falls: 170.21,
      Transport: 88.21,
      Occupant: 32.92,
      Cyclist: 18.01,
      Pedestrian: 4.35,
      Poisoning: 32.3,
      Burns: "11.8",
      Choking: 4.35,
      Drowning: 1.86
    },
    {
      name: 2011,
      Total: 658.19,
      Falls: 147.86,
      Transport: 84.31,
      Occupant: 28.94,
      Cyclist: 17.62,
      Pedestrian: 6.92,
      Poisoning: 23.28,
      Burns: 7.55,
      Choking: 6.92,
      Drowning: 1.89
    },
    {
      name: 2012,
      Total: 689.43,
      Falls: 161.69,
      Transport: 85.3,
      Occupant: 22.92,
      Cyclist: 17.82,
      Pedestrian: 3.18,
      Poisoning: 25.46,
      Burns: 4.46,
      Choking: 3.18,
      Drowning: 3.18
    }
  ],
  NL: [
    {
      name: 2006,
      Total: 722.32,
      Falls: 140.9,
      Transport: 138.22,
      Occupant: 32.1,
      Cyclist: 36.56,
      Pedestrian: 11.59,
      Poisoning: 24.08,
      Burns: 11.59,
      Choking: 0.89,
      Drowning: 1.78
    },
    {
      name: 2007,
      Total: 670.52,
      Falls: 133.92,
      Transport: 122.16,
      Occupant: 38.91,
      Cyclist: 24.43,
      Pedestrian: 9.95,
      Poisoning: 23.53,
      Burns: 15.38,
      Choking: 2.71,
      Drowning: 0
    },
    {
      name: 2008,
      Total: 704.26,
      Falls: 119.35,
      Transport: 148.51,
      Occupant: 29.15,
      Cyclist: 20.95,
      Pedestrian: 18.22,
      Poisoning: 22.78,
      Burns: 16.4,
      Choking: 0.91,
      Drowning: 0.91
    },
    {
      name: 2009,
      Total: 636.89,
      Falls: 113.14,
      Transport: 96.72,
      Occupant: 26.46,
      Cyclist: 24.64,
      Pedestrian: 9.12,
      Poisoning: 20.07,
      Burns: 8.21,
      Choking: 2.74,
      Drowning: 2.74
    },
    {
      name: 2010,
      Total: 579.54,
      Falls: 121.62,
      Transport: 87.53,
      Occupant: 21.19,
      Cyclist: 19.35,
      Pedestrian: 8.29,
      Poisoning: 19.35,
      Burns: 11.06,
      Choking: 1.84,
      Drowning: 0
    },
    {
      name: 2011,
      Total: 641.93,
      Falls: 114.43,
      Transport: 107.92,
      Occupant: 37.21,
      Cyclist: 18.61,
      Pedestrian: 6.51,
      Poisoning: 26.05,
      Burns: 13.96,
      Choking: 0,
      Drowning: 0.93
    },
    {
      name: 2012,
      Total: 667.26,
      Falls: 112.78,
      Transport: 108.08,
      Occupant: 23.49,
      Cyclist: 20.68,
      Pedestrian: 8.46,
      Poisoning: 20.68,
      Burns: 4.7,
      Choking: 3.76,
      Drowning: 0
    }
  ],
  NS: [
    {
      name: 2006,
      Total: 592.33,
      Falls: 153.39,
      Transport: 81.18,
      Occupant: 28.32,
      Cyclist: 28.32,
      Pedestrian: 7.08,
      Poisoning: 11.33,
      Burns: 4.25,
      Choking: 3.3,
      Drowning: 0
    },
    {
      name: 2007,
      Total: 643.91,
      Falls: 174.78,
      Transport: 78.27,
      Occupant: 34.57,
      Cyclist: 15.85,
      Pedestrian: 3.36,
      Poisoning: 14.41,
      Burns: 4.8,
      Choking: 4.32,
      Drowning: 0.96
    },
    {
      name: 2008,
      Total: 600.3,
      Falls: 159.56,
      Transport: 69.56,
      Occupant: 24.32,
      Cyclist: 22.38,
      Pedestrian: 5.35,
      Poisoning: 12.65,
      Burns: 7.3,
      Choking: 3.41,
      Drowning: 1.95
    },
    {
      name: 2009,
      Total: 602.6,
      Falls: 128.81,
      Transport: 69.59,
      Occupant: 26.16,
      Cyclist: 15.79,
      Pedestrian: 5.92,
      Poisoning: 12.34,
      Burns: 6.42,
      Choking: 4.44,
      Drowning: 2.47
    },
    {
      name: 2010,
      Total: 620.98,
      Falls: 124.3,
      Transport: 72.67,
      Occupant: 25.56,
      Cyclist: 16.04,
      Pedestrian: 10.53,
      Poisoning: 14.03,
      Burns: 5.51,
      Choking: 9.02,
      Drowning: 1
    },
    {
      name: 2011,
      Total: 643.73,
      Falls: 110.18,
      Transport: 81.1,
      Occupant: 32.65,
      Cyclist: 13.77,
      Pedestrian: 5.61,
      Poisoning: 14.79,
      Burns: 3.57,
      Choking: 12.24,
      Drowning: 1.02
    },
    {
      name: 2012,
      Total: 685.67,
      Falls: 130.6,
      Transport: 77.74,
      Occupant: 22.8,
      Cyclist: 16.58,
      Pedestrian: 7.77,
      Poisoning: 13.99,
      Burns: 4.15,
      Choking: 6.22,
      Drowning: 1.55
    }
  ],
  ON: [
    {
      name: 2006,
      Total: 451.23,
      Falls: 96.15,
      Transport: 56.16,
      Occupant: 19.24,
      Cyclist: 16.65,
      Pedestrian: 5.66,
      Poisoning: 13.81,
      Burns: 6.97,
      Choking: 1.6,
      Drowning: 0.99
    },
    {
      name: 2007,
      Total: 449.61,
      Falls: 93.3,
      Transport: 54.33,
      Occupant: 16.74,
      Cyclist: 15.81,
      Pedestrian: 6.51,
      Poisoning: 14.88,
      Burns: 6.83,
      Choking: 1.25,
      Drowning: 1.22
    },
    {
      name: 2008,
      Total: 441.55,
      Falls: 93.01,
      Transport: 48.98,
      Occupant: 14.72,
      Cyclist: 13.57,
      Pedestrian: 5.77,
      Poisoning: 14.21,
      Burns: 7.28,
      Choking: 1.57,
      Drowning: 1.38
    },
    {
      name: 2009,
      Total: 448.25,
      Falls: 87.36,
      Transport: 43.52,
      Occupant: 11.56,
      Cyclist: 12.65,
      Pedestrian: 5.78,
      Poisoning: 14.32,
      Burns: 5.56,
      Choking: 2.12,
      Drowning: 0.87
    },
    {
      name: 2010,
      Total: 467.86,
      Falls: 89.58,
      Transport: 43.01,
      Occupant: 12.16,
      Cyclist: 12.22,
      Pedestrian: 5.34,
      Poisoning: 12.48,
      Burns: 5.79,
      Choking: 1.87,
      Drowning: 1.58
    },
    {
      name: 2011,
      Total: 482.95,
      Falls: 88.13,
      Transport: 45.05,
      Occupant: 11.47,
      Cyclist: 14.34,
      Pedestrian: 6.25,
      Poisoning: 12.47,
      Burns: 6.73,
      Choking: 1.77,
      Drowning: 1.61
    },
    {
      name: 2012,
      Total: 479.78,
      Falls: 85.43,
      Transport: 38.65,
      Occupant: 10.69,
      Cyclist: 10.72,
      Pedestrian: 5.09,
      Poisoning: 12.83,
      Burns: 5.31,
      Choking: 2.01,
      Drowning: 1.3
    }
  ],
  PEI: [
    {
      name: 2006,
      Total: 692.84,
      Falls: 124.36,
      Transport: 136.2,
      Occupant: 94.75,
      Cyclist: 8.88,
      Pedestrian: 5.92,
      Poisoning: 26.65,
      Burns: 2.96,
      Choking: 5.92,
      Drowning: 0
    },
    {
      name: 2007,
      Total: 739.34,
      Falls: 156.28,
      Transport: 102.18,
      Occupant: 63.11,
      Cyclist: 6.01,
      Pedestrian: 3.01,
      Poisoning: 33.06,
      Burns: 6.01,
      Choking: 9.02,
      Drowning: 0
    },
    {
      name: 2008,
      Total: 721.16,
      Falls: 108.63,
      Transport: 93.54,
      Occupant: 39.23,
      Cyclist: 18.1,
      Pedestrian: 6.03,
      Poisoning: 27.16,
      Burns: 9.05,
      Choking: 6.03,
      Drowning: 3.01
    },
    {
      name: 2009,
      Total: 840.77,
      Falls: 154.24,
      Transport: 136.1,
      Occupant: 51.41,
      Cyclist: 39.32,
      Pedestrian: 3.02,
      Poisoning: 36.29,
      Burns: 6.05,
      Choking: 6.05,
      Drowning: 0
    },
    {
      name: 2010,
      Total: 628.72,
      Falls: 170.09,
      Transport: 78.97,
      Occupant: 54.67,
      Cyclist: 12.15,
      Pedestrian: 0,
      Poisoning: 6.07,
      Burns: 6.07,
      Choking: 0,
      Drowning: 0
    },
    {
      name: 2011,
      Total: 682.27,
      Falls: 127.36,
      Transport: 100.07,
      Occupant: 51.55,
      Cyclist: 3.03,
      Pedestrian: 3.03,
      Poisoning: 36.39,
      Burns: 3.03,
      Choking: 6.06,
      Drowning: 3.03
    },
    {
      name: 2012,
      Total: 819.7,
      Falls: 137.64,
      Transport: 97.87,
      Occupant: 33.64,
      Cyclist: 12.23,
      Pedestrian: 0,
      Poisoning: 6.12,
      Burns: 21.41,
      Choking: 6.12,
      Drowning: 0
    }
  ],
  SK: [
    {
      name: 2006,
      Total: 967.06,
      Falls: 218.51,
      Transport: 146.8,
      Occupant: 54.16,
      Cyclist: 25.03,
      Pedestrian: 13.82,
      Poisoning: 50.43,
      Burns: 12.33,
      Choking: 7.47,
      Drowning: 0.75
    },
    {
      name: 2007,
      Total: 941.56,
      Falls: 219.9,
      Transport: 148.59,
      Occupant: 62.35,
      Cyclist: 22.77,
      Pedestrian: 14.19,
      Poisoning: 46.67,
      Burns: 14.56,
      Choking: 9.71,
      Drowning: 2.24
    },
    {
      name: 2008,
      Total: 912.18,
      Falls: 186.44,
      Transport: 154.56,
      Occupant: 67.83,
      Cyclist: 19.64,
      Pedestrian: 16.31,
      Poisoning: 45.59,
      Burns: 17.05,
      Choking: 6.3,
      Drowning: 2.22
    },
    {
      name: 2009,
      Total: 897.19,
      Falls: 192.33,
      Transport: 143.33,
      Occupant: 56.37,
      Cyclist: 19.9,
      Pedestrian: 13.26,
      Poisoning: 41.27,
      Burns: 11.42,
      Choking: 5.16,
      Drowning: 1.47
    },
    {
      name: 2010,
      Total: 931.13,
      Falls: 189.89,
      Transport: 119.87,
      Occupant: 42.16,
      Cyclist: 17.23,
      Pedestrian: 13.56,
      Poisoning: 44.72,
      Burns: 18.7,
      Choking: 7.33,
      Drowning: 1.47
    },
    {
      name: 2011,
      Total: 855.56,
      Falls: 183.88,
      Transport: 119.67,
      Occupant: 41.96,
      Cyclist: 20.8,
      Pedestrian: 13.13,
      Poisoning: 35.02,
      Burns: 14.59,
      Choking: 4.01,
      Drowning: 1.46
    },
    {
      name: 2012,
      Total: 852.98,
      Falls: 178.23,
      Transport: 118.1,
      Occupant: 46.81,
      Cyclist: 18.36,
      Pedestrian: 9,
      Poisoning: 40.33,
      Burns: 10.8,
      Choking: 5.04,
      Drowning: 1.08
    }
  ],
  Canada: [
    {
      name: 2006,
      Total: 967.06,
      Falls: 120.28,
      Transport: 85.51,
      Occupant: 28.64,
      Cyclist: 21.87,
      Pedestrian: 8.29,
      Poisoning: 18.38,
      Burns: 7.67,
      Choking: 2.95,
      Drowning: 1.27
    },
    {
      name: 2007,
      Total: 967.06,
      Falls: 120.27,
      Transport: 80.63,
      Occupant: 26.46,
      Cyclist: 20.19,
      Pedestrian: 8.32,
      Poisoning: 19.32,
      Burns: 8.08,
      Choking: 3.0,
      Drowning: 1.24
    },
    {
      name: 2008,
      Total: 967.06,
      Falls: 114.78,
      Transport: 74.47,
      Occupant: 23.41,
      Cyclist: 17.67,
      Pedestrian: 7.55,
      Poisoning: 18.16,
      Burns: 8.72,
      Choking: 3.0,
      Drowning: 1.61
    },
    {
      name: 2009,
      Total: 967.06,
      Falls: 110.21,
      Transport: 69.01,
      Occupant: 20.53,
      Cyclist: 17.2,
      Pedestrian: 7.61,
      Poisoning: 17.79,
      Burns: 7.48,
      Choking: 3.27,
      Drowning: 1.3
    },
    {
      name: 2010,
      Total: 967.06,
      Falls: 108.87,
      Transport: 63.65,
      Occupant: 19.55,
      Cyclist: 15.45,
      Pedestrian: 6.89,
      Poisoning: 16.69,
      Burns: 7.38,
      Choking: 2.72,
      Drowning: 1.43
    },
    {
      name: 2011,
      Total: 967.06,
      Falls: 107.68,
      Transport: 64.34,
      Occupant: 18.82,
      Cyclist: 16.39,
      Pedestrian: 7.64,
      Poisoning: 17.08,
      Burns: 7.31,
      Choking: 3.36,
      Drowning: 1.52
    },
    {
      name: 2012,
      Total: 967.06,
      Falls: 105.31,
      Transport: 58.77,
      Occupant: 16.97,
      Cyclist: 14.3,
      Pedestrian: 6.92,
      Poisoning: 16.64,
      Burns: 5.92,
      Choking: 3.28,
      Drowning: 1.34
    }
  ]
};

//data for  AVERAGE hospitalization from 2006-2012 rate by injury per province.
//this data is shown in the bargraphs.
const dataBarGraph = [
  {
    name: "AB",
    Falls: 132.36,
    Transport: 97.65,
    Occupant: 31.36,
    Cyclist: 19.63,
    Pedestrian: 7.9,
    Poisoning: 19.95,
    Burns: 7.84,
    Choking: 5.12,
    Drowning: 1.5,
    AV: 678.19
  },
  {
    name: "BC",
    Falls: 125.53,
    Transport: 80.84,
    Cyclist: 24.84,
    Occupant: 22.24,
    Poisoning: 19.1,
    Pedestrian: 10.06,
    Burns: 7.24,
    Choking: 3.5,
    Drowning: 1.54,
    AV: 580.56
  },
  {
    name: "MB",
    Falls: 100.55,
    Transport: 89.93,
    Occupant: 31.8,
    Cyclist: 15.52,
    Pedestrian: 10.99,
    Poisoning: 18.25,
    Burns: 10.67,
    Choking: 3.23,
    Drowning: 1.75,
    AV: 595.26
  },
  {
    name: "NB",
    Falls: 167.51,
    Transport: 105.34,
    Occupant: 34.12,
    Cyclist: 27.87,
    Pedestrian: 5.54,
    Poisoning: 27.79,
    Burns: 9.23,
    Choking: 3.43,
    Drowning: 1.23,
    AV: 716.57
  },
  {
    name: "NL",
    Falls: 122.44,
    Transport: 115.77,
    Occupant: 29.83,
    Cyclist: 23.68,
    Pedestrian: 10.33,
    Poisoning: 22.37,
    Burns: 11.64,
    Choking: 1.83,
    Drowning: 0.92,
    AV: 660.75
  },
  {
    name: "NS",
    Falls: 140.74,
    Transport: 75.73,
    Occupant: 27.81,
    Cyclist: 18.49,
    Pedestrian: 6.49,
    Poisoning: 13.34,
    Burns: 5.15,
    Choking: 6.07,
    Drowning: 1.27,
    AV: 626.39
  },
  {
    name: "ON",
    Falls: 90.44,
    Transport: 47.12,
    Occupant: 13.81,
    Cyclist: 13.72,
    Pedestrian: 5.77,
    Poisoning: 13.57,
    Burns: 6.36,
    Choking: 1.74,
    Drowning: 1.28,
    AV: 460.13
  },
  {
    name: "PEI",
    Falls: 139.75,
    Transport: 106.53,
    Occupant: 55.64,
    Cyclist: 14.23,
    Pedestrian: 3.02,
    Poisoning: 24.58,
    Burns: 7.76,
    Choking: 5.61,
    Drowning: 0.86,
    AV: 731.94
  },
  {
    name: "SK",
    Falls: 195.44,
    Transport: 135.69,
    Occupant: 53.01,
    Cyclist: 20.51,
    Pedestrian: 13.31,
    Poisoning: 43.49,
    Burns: 14.2,
    Choking: 6.42,
    Drowning: 1.53,
    AV: 907.82
  }
];

//data for timeline chart (policies) by province
const policyData = {
  BC: [
    { year: 1996, Yindex: 0, policy: "Helmet Legislation" },
    { year: 1998, Yindex: 0, policy: "Graduated Driver’s Licensing" },
    { year: 2003, Yindex: 0, policy: "Helmet Legislation (revised)" },
    { year: 2008, Yindex: 0, policy: "Booster Seat Legislation" },
    { year: 2016, Yindex: 0, policy: "Distracted Driving Laws" }
  ],
  NS: [
    { year: 1994, Yindex: 0, policy: "Graduated Driver’s Licensing" },
    { year: 1997, Yindex: 0, policy: "Helmet Legislation" },
    { year: 2007, Yindex: 0, policy: "Booster Seat Legislation" },
    { year: 2007.1, Yindex: 0, policy: "Helmet Legislation (revised)" }, //issue with no reference dot on timeline when data points in same year. Therefore needed to add the 1 at end of 2017 and delete later.
    { year: 2008, Yindex: 0, policy: "Distracted Driving Laws" }
  ],
  ON: [
    { year: 1994, Yindex: 0, policy: "Graduated Driver’s Licensing" },
    { year: 1995, Yindex: 0, policy: "Helmet Legislation (revised)" },
    { year: 2005, Yindex: 0, policy: "Booster Seat Legislation" },
    { year: 2009, Yindex: 0, policy: "Distracted Driving Laws" }
  ],
  PEI: [
    { year: 2000, Yindex: 0, policy: "Graduated Driver’s Licensing" },
    { year: 2003, Yindex: 0, policy: "Helmet Legislation (revised)" },
    { year: 2008, Yindex: 0, policy: "Booster Seat Legislation" },
    { year: 2010, Yindex: 0, policy: "Distracted Driving Laws" }
  ],
  SK: [
    { year: 2005, Yindex: 0, policy: "Graduated Driver’s Licensing" },
    { year: 2010, Yindex: 0, policy: "Booster Seat Legislation" },
    { year: 2014, Yindex: 0, policy: "Distracted Driving Laws" }
  ],
  AB: [
    { year: 2002, Yindex: 0, policy: "Helmet Legislation (revised)" },
    { year: 2011, Yindex: 0, policy: "Graduated Driver’s Licensing" }
  ],
  MB: [
    { year: 2003, Yindex: 0, policy: "Graduated Driver’s Licensing" },
    { year: 2010, Yindex: 0, policy: "Distracted Driving Laws" },
    { year: 2012, Yindex: 0, policy: "Booster Seat Legislation" },
    { year: 2013, Yindex: 0, policy: "Helmet Legislation (revised)" }
  ],
  NB: [
    { year: 1995, Yindex: 0, policy: "Helmet Legislation (revised)" },
    { year: 1996, Yindex: 0, policy: "Graduated Driver’s Licensing" },
    { year: 2008, Yindex: 0, policy: "Booster Seat Legislation" },
    { year: 2011, Yindex: 0, policy: "Distracted Driving Laws" }
  ],
  NL: [
    { year: 1999, Yindex: 0, policy: "Graduated Driver’s Licensing" },
    { year: 2008, Yindex: 0, policy: "Booster Seat Legislation" },
    { year: 2010, Yindex: 0, policy: "Distracted Driving Laws" },
    { year: 2015, Yindex: 0, policy: "Helmet Legislation (revised)" }
  ]
};

const provinceIntroPara = {
  AB:
    "In 2012, 6,513 Albertans aged 0–19 were hospitalized due to an injury. This resulted in 679.99 hospitalizations per 100,000 given a provincial population of 957,805 children and youth. On average, injury caused 8.70 deaths per 100,000 population. The rate of injury deaths from all causes decreased for children and adolescents aged 0-19 between 2006 and 2012 from 11.62 to 7.41 per 100,000. The fatality rate was consistently above the Canadian average but decreased by -36.23% over time.",
  BC:
    "In 2012, 4,974 British Columbians aged 0–19 were hospitalized due to an injury. This resulted in 515.72 hospitalizations per 100,000 given a provincial population of 964,482 children and youth. On average, injury caused 8.35 deaths per 100,000 population. The rate of injury deaths from all causes decreased for children and adolescents aged 0–19 between 2006 and 2012 from 9.69 to 7.10 per 100,000. The fatality rate was consistently above the Canadian average but decreased by -26.73% over time.",
  MB:
    "In 2012, 1,796 Manitobans aged 0–19 were hospitalized due to an injury. This resulted in 555.70 hospitalizations per 100,000 given a provincial population of 323,194 children and youth. On average, injury caused 11.93 deaths per 100,000 population. The rate of injury deaths from all causes increased for children and adolescents aged 0–19 between 2006 and 2012 from 11.72 to 11.93 per 100,000. The fatality rate was consistently above the Canadian average and increased by +10.92% over time.",
  NB:
    "In 2012, 4,974 New Brunswickens aged 0–19 were hospitalized due to an injury. This resulted in 515.72 hospitalizations per 100,000 given a provincial population of 964,482 children and youth. On average, injury caused 8.35 deaths per 100,000 population. The rate of injury deaths from all causes decreased for children and adolescents aged 0–19 between 2006 and 2012 from 14.91 to 12.10 per 100,000. The fatality rate was consistently above the Canadian average but decreased by -18.85% over time.",
  NL:
    "In 2012, 710 children and youth from Newfoundland & Labrador aged 0–19 were hospitalized due to an injury. This resulted in 667.26 hospitalizations per 100,000 given a provincial population of 106,406 children and youth. On average, injury caused 8.35 deaths per 100,000 population. The rate of injury deaths from all causes increased for children and adolescents aged 0–19 between 2006 and 2012 from 6.24 to 6.58 per 100,000. The fatality rate was above the Canadian average in most years and increased by +5.45% over time.",
  NS:
    "In 2012, 1,323 Nova Scotians aged 0–19 were hospitalized due to an injury. This resulted in 685.67 hospitalizations per 100,000 given a provincial population of 192,949 children and youth. On average, injury caused 9.81 deaths per 100,000 population. The rate of injury deaths from all causes decreased for children and adolescents aged 0–19 between 2006 and 2012 from 11.80 to 8.81 per 100,000. The fatality rate was consistently above the Canadian average but decreased by -25.34% over time.",
  ON:
    "In 2012, 14,810 Ontarians aged 0–19 were hospitalized due to an injury. This resulted in 479.78 hospitalizations per 100,000 given a provincial population of 3,086,848 children and youth. On average, injury caused 5.31 deaths per 100,000 population. The rate of injury deaths from all causes decreased for children and adolescents aged 0–19 between 2006 and 2012 from 7.48 to 5.31 per 100,000. The fatality rate was below the Canadian average in most years and decreased by -29.01% over time.",
  PEI:
    "In 2012, 268 children and youth from Prince Edward Island (PEI) aged 0–19 were hospitalized due to an injury. This resulted in 819.70 hospitalizations per 100,000 given a provincial population of 32,695 children and youth. On average, injury caused 10.35 deaths per 100,000 population. The rate of injury deaths from all causes decreased for children and adolescents aged 0–19 between 2006 and 2012 from 23.69 to 6.12 per 100,000. The fatality rate decreased by -74.17% over tim, however the death rate in 2006 was abnormally high.",
  SK:
    "In 2012, 2,369 Saskatchewans aged 0–19 were hospitalized due to an injury. This resulted in 852.98 hospitalizations per 100,000 given a provincial population of 277,731 children and youth. On average, injury caused 17.51 deaths per 100,000 population. The rate of injury deaths from all causes increased for children and adolescents aged 0–19 between 2006 and 2012 from 13.45 to 17.51 per 100,000. The fatality rate was consistently above the Canadian average and increased by +17.77% over time."
};

const BarChartSectiondataInfo = {
  BC: {
    Falls:
      "Falls accounted for the highest number of injury hospitalizations among children and youth with an average of 125.53 hospitalizations per 100,000 however these injuries decreased by -21.31% over time. British Columbia (BC) had the highest percent decrease for fall-related injury hospitalizations when provinces were compared.",
    Transport:
      "The rate of transport-related injury hospitalizations decreased for children and adolescents aged 0–19 between 2006 and 2012 from 103.08 to 60.76 per 100,000. Although the rate of transport-related injury decreased in BC over time it still remained above the Canadian average in 2012. Overall, transport-related hospitalization rates decreased in BC by -41.06% over time.",
    Cyclist:
      "In BC, the rate of cyclist-related injury hospitalizations has decreased by -42.14% over time.",
    Pedestrian:
      "Pedestrian-related injury hospitalizations have decreased in Canada by almost one quarter over time (-24.13%). In BC these injuries have decreased by over one third (-34.60%). ",
    Poisoning:
      "The rate of injury hospitalization from unintentional poisoning in BC remained relatively stable over time from 20 per 100,000 in 2006 to 19.10 per 100,000 in 2012.",
    Burns:
      "Burn-related injuries have decreased by over half in BC over time (-51.52%). The rate of burn-related hospitalizations was below the Canadian average in 2012 (4.15 per 100,000).",
    Choking:
      "Choking-related injury hospitalizations have been increasing in Canada and BC over time. In 2006, the rate of choking-related hospitalization was 3.4 per 100,000 and this increased by 12.94% to 3.84 per 100,000 by 2012.",
    Drowning:
      "Drowning-related injuries accounted for the lowest rate –1.54 per 100,000 and decreased in BC by -59.71% over time. ",
    Occupant:
      "Occupant-related injury hospitalizations have decreased by over 50% by 2012. Additionally, BC’s rate of injury hospitalization for motor vehicle occupants was below the Canadian average by the end of the study year."
  },
  AB: {
    Falls:
      "Falls accounted for the highest number of injury hospitalizations among children and youth with an average of 132.36 hospitalizations per 100,000 however these injuries decreased by -3.97% over time.",
    Transport:
      "The rate of transport-related injury hospitalizations decreased for children and adolescents aged 0–19 between 2006 and 2012 from 114.57 to 83.42 per 100,000. Although the rate of transport-related injury decreased in AB over time it still remained above the Canadian average in 2012. Overall, transport-related hospitalization rates decreased in AB by -27.19% over time.",
    Cyclist:
      "In AB, the rate of cyclist-related injury hospitalizations has decreased by -2.60% over time.",
    Pedestrian:
      "Pedestrian-related injury hospitalizations have decreased in Canada by almost one quarter over time (-24.13%). In AB these injuries have decreased by -42.68%.",
    Poisoning:
      "The rate of injury hospitalization from unintentional poisoning in AB decreased from 20.46 per 100,000 in 2006 to 17.96 per 100,000 in 2012.",
    Burns:
      "Burn-related injuries have decreased slightly over time (-0.57%). The rate of burn-related hospitalizations was slightly above the Canadian average in 2012 (7.84 per 100,000).",
    Choking:
      "Choking-related injury hospitalizations have been increasing in Canada but decreasing in AB over time. In 2006, the rate of choking-related hospitalization was 6.15 per 100,000 and this increased by 18.54% to 5.01 per 100,000 by 2012.   ",
    Drowning:
      "Drowning-related injuries accounted for the lowest rate of hospitalization –1.50 per 100,000 and increased in AB by + 15.17% over time.",
    Occupant:
      "Occupant-related injury hospitalizations have decreased by one third -33.63% by 2012. AB’s rate of injury hospitalization for motor vehicle occupants was still above the Canadian average by the end of the study year."
  },
  MB: {
    Falls:
      "Falls accounted for the highest number of injury hospitalizations among children and youth with an average of 100.55 hospitalizations per 100,000 however these injuries decreased by -15.45% over time.",
    Transport:
      "The rate of transport-related injury hospitalizations decreased for children and adolescents aged 0–19 between 2006 and 2012 from 118.11 to 67.45 per 100,000. Although the rate of transport-related injury decreased in MB over time it still remained above the Canadian average in 2012. Overall, transport-related hospitalization rates decreased in MB by -42.89% over time. ",
    Cyclist:
      "In MB, the rate of cyclist-related injury hospitalizations has decreased by -43.94% over time.  ",
    Pedestrian:
      "Pedestrian-related injury hospitalizations have decreased in Canada by almost one quarter over time (-24.13%). In MB these injuries have decreased by -10.07%.",
    Poisoning:
      "The rate of injury hospitalization from unintentional poisoning in MB increased over time from 17.42 per 100,000 in 2006 to 20.42 per 100,000 in 2012.",
    Burns:
      "Burn-related injuries have decreased by -21.32% over time in MB. The rate of burn-related hospitalizations was consistently above the Canadian average.",
    Choking:
      "Choking-related injury hospitalizations have been increasing in Canada and MB over time. In 2006, the rate of choking-related hospitalization was 3.17 per 100,000 and this increased by +17.03% to 3.71 per 100,000 by 2012.",
    Drowning:
      "Drowning-related injuries accounted for the lowest rate –1.75 per 100,000 and decreased in MB by -2.11% over time.",
    Occupant:
      "Occupant-related injury hospitalizations have decreased by almost 50% by 2012. MB’s rate of injury hospitalization for motor vehicle occupants was still above the Canadian average by the end of the study year."
  },
  NB: {
    Falls:
      "Falls accounted for the highest number of injury hospitalizations among children and youth with an average of 167.51 hospitalizations per 100,000 however these injuries decreased by -6.81% over time.",
    Transport:
      "The rate of transport-related injury hospitalizations decreased for children and adolescents aged 0–19 between 2006 and 2012 from 153.24 to 85.31 per 100,000. Although the rate of transport-related injury decreased in NB over time it still remained above the Canadian average in 2012. Overall, transport related hospitalization rates decreased in NB by -44.33% over time.",
    Cyclist:
      "In NB, the rate of cyclist-related injury hospitalizations has decreased by -60.15% over time.",
    Pedestrian:
      "Pedestrian-related injury hospitalizations have decreased in Canada by almost one quarter over time (-24.13%). In NB these injuries have decreased by approximately one third (-33.33%).",
    Poisoning:
      "The rate of injury hospitalization from unintentional poisoning in NB decreased over time from 29.81 per 100,000 in 2006 to 25.46 per 100,000 in 2012.",
    Burns:
      "Burn0-related injuries have decreased by -42.45% over time. The rate of burn-related hospitalizations was below the Canadian average in 2012 (4.46 per 100,000).",
    Choking:
      "Choking-related injury hospitalizations have been increasing in Canada and NB over time. In 2006, the rate of choking-related hospitalization was abnormally low (0.60 per 100,000) and this increased by 430% to 3.18 per 100,000 by 2012.",
    Drowning:
      "Drowning-related injuries accounted for the lowest rate –1.54 per 100,000 and increased in NB by +12.94% over time.",
    Occupant:
      "Occupant-related injury hospitalizations have decreased by over 50% by 2012. NB’s rate of injury hospitalization for motor vehicle occupants was still above the Canadian average by the end of the study year. "
  },
  NL: {
    Falls:
      "Falls accounted for the highest number of injury hospitalizations among children and youth with an average of 122.44 hospitalizations per 100,000 however these injuries decreased by -19.96% over time.",
    Transport:
      "The rate of transport-related injury hospitalizations decreased for children and adolescents aged 0–19 between 2006 and 2012 from 138.22 to 108.08 per 100,000. The rate of transport-related injury decreased in NL over time but remained above the Canadian average in 2012. Overall, transport-related hospitalization rates decreased in NL by -21.81% over time.",
    Cyclist:
      "In NL, the rate of cyclist-related injury hospitalizations has decreased by -43.44% over time.",
    Pedestrian:
      "Pedestrian-related injury hospitalizations have decreased in Canada by almost one quarter over time (-24.13%). In NL these injuries have decreased by -27.00% over time.",
    Poisoning:
      "The rate of injury hospitalization from unintentional poisoning in NL decreased over time from 24.08 per 100,000 in 2006 to 20.68 per 100,000 in 2012.",
    Burns:
      "Burn-related injuries have decreased by over half in NL over time (-59.45%). The rate of burn-related hospitalizations was below the Canadian average in 2012 (4.70 per 100,000).",
    Choking:
      "Choking-related injury hospitalizations have been increasing in Canada and NL over time. In 2006, the rate of choking-related hospitalization was abnormally low (0.89 per 100,000) and this increased by +322.47% to 3.76 per 100,000 by 2012.",
    Drowning:
      "Drowning-related injuries accounted for the lowest rate –0.92 per 100,000 and decreased in NL by -100% over time.",
    Occupant:
      "Occupant-related injury hospitalizations have decreased by over a quarter (-26.82%) by 2012. NL’s rate of injury hospitalization for motor vehicle occupants was still above the Canadian average by the end of the study year."
  },
  NS: {
    Falls:
      "Falls accounted for the highest number of injury hospitalizations among children and youth with an average of 140.74 hospitalizations per 100,000 however these injuries decreased by -14.86% over time.",
    Transport:
      "The rate of transport-related injury hospitalizations decreased for children and adolescents aged 0–19 between 2006 and 2012 from 81.18 to 77.74 per 100,000. Although the rate of transport-related injury decreased in NS over time it still remained above the Canadian average in 2012. Overall, transport-related hospitalization rates decreased in NS by -4.24% over time.",
    Cyclist:
      "In NS, the rate of cyclist-related injury hospitalizations has decreased by -41.45% over time.",
    Pedestrian:
      "Pedestrian-related injury hospitalizations have decreased in Canada by almost one quarter over time (-24.13%). In NS these injuries have increased by 9.75% over time.",
    Poisoning:
      "The rate of injury hospitalization from unintentional poisoning in NS increased over time by almost a quarter (+23.48%).",
    Burns:
      "Burn-related injuries have decreased slightly in NS over time (-2.35%). The rate of burn-related hospitalizations was below the Canadian average in 2012 (4.15 per 100,000).",
    Choking:
      "Choking-related injury hospitalizations have been increasing in Canada and NS over time. In 2006, the rate of choking-related hospitalization was 3.30 per 100,000 and this increased by 88.48% to 6.22 per 100,000 by 2012.",
    Drowning:
      "Drowning-related injuries account for the lowest rate –1.27 per 100,000 and remained unchanged in NS over time.",
    Occupant:
      "Occupant-related injury hospitalizations have decreased by almost 20% by 2012. NS rate of injury hospitalization for motor vehicle occupants was still above the Canadian average by the end of the study year."
  },
  ON: {
    Falls:
      "Falls accounted for the highest number of injury hospitalizations among children and youth with an average of 90.44 hospitalizations per 100,000 however these injuries decreased by -11.15% over time.",
    Transport:
      "The rate of transport-related injury hospitalizations decreased for children and adolescents aged 0–19 between 2006 and 2012 from 56.16 to 38.65 per 100,000. The rate of transport-related injury in Ontario remained below the Canadian average over time. Overall, transport-related hospitalization rates decreased in ON by -31.18% over time.",
    Cyclist:
      "In ON, the rate of cyclist-related injury hospitalizations has decreased by -35.62% over time.",
    Pedestrian:
      "Pedestrian-related injury hospitalizations have decreased in Canada by almost one quarter over time (-24.13%). In ON these injuries have decreased by -10.07%.",
    Poisoning:
      "The rate of injury hospitalization from unintentional poisoning in ON remained relatively stable over time from 13.81 per 100,000 in 2006 to 12.83 per 100,000 in 2012",
    Burns:
      "Burn-related injuries have decreased by almost a quarter in ON over time (-23.82%). The rate of burn-related hospitalizations was below the Canadian average over time.",
    Choking:
      "Choking-related injury hospitalizations have been increasing in Canada and ON over time. In 2006, the rate of choking-related hospitalization was 1.60 per 100,000 and this increased by 25.62% to 2.01 per 100,000 by 2012.",
    Drowning:
      "Drowning-related injuries accounted for the lowest rate –1.28 per 100,000 and increased in ON by +31.31% over time.",
    Occupant:
      "Occupant-related injury hospitalizations have decreased by over 44% by 2012. Additionally, ON’s rate of injury hospitalization for motor vehicle occupants was consistently below the Canadian average."
  },
  PEI: {
    Falls:
      "Falls accounted for the highest number of injury hospitalizations among children and youth with an average of 139.75 hospitalizations per 100,000, these injuries increased by +10.68% over time.",
    Transport:
      "The rate of transport-related injury hospitalizations decreased for children and adolescents aged 0–19 between 2006 and 2012 from 136.20 to 97.87 per 100,000. Although the rate of transport-related injury decreased in PEI over time it still remained above the Canadian average in 2012. Overall, transport-related hospitalization rates decreased in PEI by -28.14% over time.",
    Cyclist:
      "In PEI, the rate of cyclist-related injury hospitalizations has increased by +37.73% over time. ",
    Pedestrian:
      "Pedestrian-related injury hospitalizations have decreased in Canada by almost one quarter over time (-24.13%). In PEI these injuries have decreased to zero by 2012.",
    Poisoning:
      "The rate of injury hospitalization from unintentional poisoning in PEI decreased over time from 26.65 per 100,000 in 2006 to 6.12 per 100,000 in 2012.",
    Burns:
      "Burn-related injuries have decreased 623.31% over time, however there was an abnormally low rate in 2006 (2.96 per 100,000) and an abnormally high rate (21.41 per 100,000) in 2012. The average rate of burn-related hospitalizations was 7.76 per 100,000.",
    Choking:
      "Choking-related injury hospitalizations have been increasing in Canada and PEI over time. In 2006, the rate of choking-related hospitalization was 5.92 per 100,000 and this increased by 3.38% to 6.12 per 100,000 by 2012.",
    Drowning:
      "Drowning-related injuries accounted for the lowest rate –0.86 per 100,000. The drowning-related hospitalization rate in PEI was below the Canadian average except in 2008 and 2011.",
    Occupant:
      "Occupant-related injury hospitalizations have decreased by almost two thirds (-64.50%) by 2012. PEI’s rate of injury hospitalization for motor vehicle occupants was above the Canadian average during the study period."
  },
  SK: {
    Falls:
      "Falls accounted for the highest number of injury hospitalizations among children and youth with an average of 195.44 hospitalizations per 100,000 however these injuries decreased by -18.43% over time.",
    Transport:
      "The rate of transport-related injury hospitalizations decreased for children and adolescents aged 0–19 between 2006 and 2012 from 146.80 to 118.10 per 100,000. Although the rate of transport-related injury decreased in SK over time it still remained above the Canadian average in 2012. Overall, transport-related hospitalization rates decreased in SK by -19.55% over time.",
    Cyclist:
      "In SK, the rate of cyclist-related injury hospitalizations has decreased by -26.65% over time.",
    Pedestrian:
      "Pedestrian-related injury hospitalizations have decreased in Canada by almost one quarter over time (-24.13%). In SK these injuries have decreased by -13.57% over time.",
    Poisoning:
      "The rate of injury hospitalization from unintentional poisoning in SK decreased over time from 50.43 per 100,000 in 2006 to 40.33 per 100,000 in 2012.",
    Burns:
      "Burn-related injuries have decreased in SK by -12.41% over time. The rate of burn-related hospitalizations was still above the Canadian average in 2012 (10.80 per 100,000).",
    Choking:
      "While choking-related injury hospitalizations have been increasing in Canada they have been decreasing in SK over time. In 2006, the rate of choking-related hospitalization was 7.47 per 100,000 and this decreased by -32.53% to 5.04 per 100,000 by 2012.",
    Drowning:
      "Drowning-related injuries accounted for the lowest rate –1.53 per 100,00 and increased in SK by +44.00% over time.",
    Occupant:
      "Occupant-related injury hospitalizations have decreased by -13.57% by 2012. SK’s rate of injury hospitalization for motor vehicle occupants was still above the Canadian average by the end of the study year. "
  },
  Canada: {
    Falls:
      "Falls accounted for the highest number of injury hospitalizations among children and youth with an average of 112.49 hospitalizations per 100,000 however these injuries decreased by -12.45% over time. ",
    Transport:
      "The rate of transport-related injury hospitalizations decreased for children and adolescents aged 0–19 between 2006 and 2012 from 85.51 to 70.91 per 100,000. Overall, transport-related hospitalization rates decreased in Canada by -31.27% over time.",
    Cyclist:
      "In Canada, the rate of cyclist-related injury hospitalizations has decreased by -34.61% over time.  ",
    Pedestrian:
      "Pedestrian-related injury hospitalizations have decreased in Canada by almost one quarter over time -24.13%. ",
    Poisoning:
      "The rate of injury hospitalization from unintentional poisoning in Canada remained relatively stable over time from 18.38 per 100,000 in 2006 to 16.64 per 100,000 in 2012.",
    Burns:
      "The rate of burn-related injury hospitalizations decreased for children and adolescents aged 0–19 between 2006 and 2012 from 7.67 to 5.92 per 100,000.",
    Choking:
      "Choking-related injury hospitalizations have been increasing in Canada over time. In 2006, the rate of choking-related hospitalization was 2.95 per 100,000 and this increased by 11.19% to 3.28 per 100,000 by 2012.",
    Drowning:
      "Drowning-related injuries accounted for the lowest rate –1.39 per 100,000 and increased in Canada by 5.51% over time.",
    Occupant:
      "Occupant-related injury hospitalizations have decreased by over 40% by 2012."
  },

  Research: {
    Falls:
      "The Child Safety Good Practice Guide suggests that window safety mechanisms such as bars and position-locking devices are an effective strategy to prevent children from sustaining fall-related injuries (Mackay et al., 2011; Spiegel et al., 1995). Stair gates have also been shown to assist in the reduction of falls down stairs to young children (Mackay et al., 2011; Towner et al., 2001). Other evidence-based policies such as the implementation of safer playground equipment have demonstrated a decrease in the injury rate for children over time (Canadian Standards Association, 2007; Howard et al., 2005; Mackay et al., 2011). Policies and legislations related to the prevention of fall-related injuries in children/youth have shown evidence of effectiveness on a federal level.",
    Transport:
      "A Cochrane Systematic Review concluded that stronger GDL programs (i.e. programs that involve more restrictions for novice drivers) appear to result in a greater reduction in mortality from motor vehicle crashes among young drivers (Russell et al., 2011). Snowdon et al. (2009) performed the first Canadian study that evaluated the effectiveness of booster seat legislation on restraint use among children aged 4-8 years old. They found that in provinces with legislation, 91.9% of children were restrained and of those 24.6% used a booster seat specifically. Conversely, 84.4% of children in provinces without legislation were restrained and of those only 16.6% used a booster seat. Studies demonstrate that all age laws with steeper fines increase levels of helmet use compliance (Macpherson & Spinks, 2008).",
    Cyclist:
      "Evidence suggests that helmet use is greater in areas with all age helmet laws as opposed to those that only apply to children (Dennis et al., 2010; Hagel et al., 2006).",
    Pedestrian:
      "Pedestrian safety laws are another important policy that may affect the number of children being hospitalized or dying from pedestrian motor vehicle collisions (PMVCs). Pedestrian safety laws are often multifactorial and can involve changes to the built environment, traffic calming strategies, speed reduction, and on street parking limits (AAP, 2009). Previous studies suggest that PMVCs in children/youth who walk to and from school as a form of active transportation are related to the built environment (Rothman et al., 2014). Currently, pedestrian safety laws are mandated on a municipal level and differences between jurisdictions are not well documented.",
    Poisoning:
      "Unintentional poisoning related injuries are another significant cause of hospitalization and death in Canada. Over 1,500 people died as the result of an unintentional poisoning related injury in 2010 (Parachute, 2015). Strategies that have been suggested to reduce the burden of these injuries include secure storage for poisons, child resistant packaging, and the availability of poison control centers (Mackay et al., 2011; Krug et al. 1994; Woolf et al., 1992).",
    Burns:
      "Evidence-based policies for the prevention of burn and scald related injuries include legislation regulating the temperature of hot water from household taps, however these are not currently regulated on a provincial basis (Peden et al., 2008). Other effective prevention strategies include product modification for child resistant lighters.",
    Choking:
      "Choking hazards are primarily associated with food, coins, and toys (American Academy of Pediatrics [AAP], 2010). Food is one cause of choking-related injuries in children under 3 years of age, and a mandatory system to label foods with appropriate warnings according to their choking risk has been suggested (AAP, 2010). The number of choking-related injuries are increasing in Canada over time; policies that focus on reducing these injuries should be implemented on a national basis. In 2010, Bill C-36 became law and introduced new safety legislation that suppliers must comply with so as to better protect the health of Canadians through the manufacturing of safer products. Regulations were also put into effect for product recalls if they were deemed to be unsafe, or pose unnecessary risk to the consumer (Parliament of Canada, 2010).",
    Drowning:
      "The first global report on drowning claims that 75% of private back-yard/garden swimming pool drowning deaths among young children could be prevented by four-sided pool fencing, completely separating the pool from the house/yard (World Health Organization [WHO], 2014). Safer pool fencing legislation is currently mandated at a municipal level and varies greatly within and among provinces in Canada (Parachute, 2011). Four-sided pool fencing is one effective prevention policy that should be uniformly implemented across Canada to reduce the burden of childhood drowning-related hospitalizations and fatalities.",
    Occupant:
      "The effectiveness of booster seat legislation has been evaluated in the United States and Canada. A case-control study by Farmer et al. (2009) examined the association between booster seat legislation and fatality among 4-8 year old children involved in a frontal motor vehicle crash. They found that children who were involved in a crash in a state with a booster seat law were significantly less likely to die. In addition, they found that being restrained in a booster seat had an even greater protective effect for children than being restrained in a seat belt. The authors concluded that states that had enacted booster seat legislation had a higher rate of child restraint and correct booster seat use than states with no law, and there was a 20% reduction in fatalities under legislation."
  }
};

const TimelinedataParagraph = {
  AB:
    "Alberta has done a good job of addressing injury prevention policies in the areas of distracted driving, graduated driver’s licensing, and smoke and carbon monoxide detector laws. AB also has fair pedestrian safety law and bicycle helmet legislation. AB is the only province that currently does not have booster seat legislation in place. A detailed breakdown of each policy is described below.",
  BC:
    "British Columbia has done an excellent job of addressing injury prevention policies in the areas of distracted driving, booster seat legislation, and bicycle helmet legislation. BC also has a good graduated driver’s licensing program and fair smoke and carbon monoxide detector and pedestrian safety laws. A detailed breakdown of each policy is described below.",
  MB:
    "Manitoba has done an excellent job of addressing injury prevention policies in the area of smoke and carbon monoxide detector laws. MB also has good distracted driving law and booster seat legislation and fair graduated driver’s licensing programs, bicycle helmet legislation, and pedestrian safety laws. A detailed breakdown of each policy is described below.",
  NB:
    "New Brunswick has done a good job of addressing injury prevention policies in the areas of distracted driving, booster seat legislation, bicycle helmet legislation, pedestrian safety law, and graduated driver’s licensing program but does not have a provincial carbon monoxide detector law. A detailed breakdown of each policy is described below.",
  NL:
    "Newfoundland & Labrador has done a good job of addressing injury prevention policies in the areas of distracted driving, bicycle helmet legislation, and graduated driver’s licensing programs. NL also has fair smoke and carbon monoxide laws, pedestrian safety laws, and booster seat legislation. A detailed breakdown of each policy is described below.",
  NS:
    "Nova Scotia has done an excellent job of addressing injury prevention policies in the areas of booster seat legislation, and bicycle helmet legislation. NS also has good distracted driving and pedestrian safety laws. NS has a fair smoke and carbon monoxide detector law and graduated driver’s licensing program. A detailed breakdown of each policy is described below.",
  ON:
    "Ontario has done an excellent job of addressing injury prevention policies in the areas of distracted driving, booster seat legislation, and pedestrian safety laws. ON also has a good graduated driver’s licensing program and smoke and carbon monoxide detector law and fair bicycle helmet legislation. A detailed breakdown of each policy is described below. ",
  PEI:
    "Prince Edward Island has done an excellent job of addressing injury prevention policies in the areas of distracted driving and booster seat legislation. PEI also has a good graduated driver’s licensing program and booster seat legislation and fair smoke and carbon monoxide detector and pedestrian safety laws. A detailed breakdown of each policy is described below.",
  SK:
    "Saskatchewan has done a good job of addressing injury prevention policies in the areas of distracted driving and booster seat legislation. SK also has a fair graduated driver’s licensing program. SK does not have policies in place for smoke and carbon monoxide detectors, pedestrian safety laws, or bicycle helmet legislation. A detailed breakdown of each policy is described below."
};

const ScoreCardRatingInfo = {
  graduated: [
    [
      "EXCELLENT",
      "3",
      "elimination of 'time discounts' for drivers who attend education programmes"
    ],
    [
      "GOOD",
      "2",
      "mandatory requirement for supervised practice of at least 50 hours"
    ],
    ["FAIR", "1", "minimum 12 months' duration in the learners phase"],
    ["NONE", "0", "less than 12 months of duration in the learners phase"]
  ],
  pedestrian: [
    [
      "EXCELLENT",
      "3",
      "legislation including speed limits in residential zones of <50 km, speed limits in school zones, and double fines in both residential and school zones"
    ],
    [
      "GOOD",
      "2",
      "speed limits in residential, school and pedestrian zones, no double fines"
    ],
    ["FAIR", "1", "only speed limit in residential zones"],
    [
      "NONE",
      "0",
      "speed limits in both residential and school zones are set by local municipalities"
    ]
  ],
  booster: [
    [
      "EXCELLENT",
      "3",
      "age/weight and height restrictions, public education and incentive programmes, non-compliance penalties, and driver responsibility"
    ],
    ["GOOD", "2", "only had age/weight and height restrictions"],
    ["FAIR", "1", "no public education or incentive programmes"],
    ["NONE", "0", "no enacted booster seat legislation"]
  ],
  bicycle: [
    [
      "EXCELLENT",
      "3",
      "legislation that applies to all ages and all wheeled activities including non-motorised skates, skateboards, and push scooters"
    ],
    [
      "GOOD",
      "2",
      "legislation that applies to all ages but does not apply to other wheeled activities"
    ],
    [
      "FAIR",
      "1",
      "legislation that only applies to children/youth age 18 and under"
    ],
    ["NONE", "0", "no legislation mandating the use of helment in cycling"]
  ],
  distracted: [
    [
      "EXCELLENT",
      "3",
      "provisions on handheld electronic entertainment devices & handheld communication devices, fines from $490 to $1200 for 1st and 2nd offences, and 3-5 demerit points"
    ],
    [
      "GOOD",
      "2",
      "did not ban handheld entertainment devices and fines from $100 to $350"
    ],
    ["FAIR", "1", "fines less then $100 to $350"],
    [
      "NONE",
      "0",
      "no law in place for handheld electronic & communication devices"
    ]
  ],
  smoke: [
    [
      "EXCELLENT",
      "3",
      "if smoke and CO detectors are mandated to be installed on each floor in a dwelling"
    ],
    [
      "GOOD",
      "2",
      "if smoke and CO detectors are required in all dwellings but not necessarily each floor"
    ],
    [
      "FAIR",
      "1",
      "if smoke detector is required but not a CO detector in each dwelling"
    ],
    [
      "NONE",
      "0",
      "if there are no provincial smoke or CO detector laws in place"
    ]
  ]
};

const TimelinePopUpInfo = {
  AB: {
    HelmetLegislationrevised: [
      ["Age", "Applies to all ages", "Applies only to those <18 years of age"],
      ["Penalty", "Fine", "Fine: $69"],
      ["Includes all wheeled activities", "Yes", "No"]
    ],
    GraduatedDriversLicensing: [
      ["Supervisor Driver", "Required", "Yes (18 years old fully licensed)"],
      ["Minimum Age", "Yes", "14 years"],
      ["Driver Education/Training", "Required", "Voluntary"],
      ["Blood Alcohol Content", "Zero", "Zero"],
      [
        "Restrictions",
        "Limitations in place",
        "Yes: no driving between 12 AM – 5 AM"
      ],
      [
        "Passengers",
        "Limitations in place",
        "Limit to number of working seatbelts"
      ],
      ["Sign on Vehicle", "Required", "None"]
    ]
  },
  BC: {
    HelmetLegislation: [
      ["Age", "Applies to all ages", "Applies to all ages"],
      ["Penalty", "Fine", "Fine up to: $100"],
      ["Includes all wheeled activities", "Yes", "No"]
    ],
    HelmetLegislationrevised: [
      ["Age", "Applies to all ages", "Applies to all ages"],
      ["Penalty", "Fine", "Fine up to: $100"],
      ["Includes all wheeled activities", "Yes", "Yes"]
    ],
    GraduatedDriversLicensing: [
      [
        "Supervisor Driver",
        "Required",
        "Yes (25 years or older with a valid class 1-5 driver’s license)"
      ],
      ["Minimum Age", "Yes", "16 years"],
      ["Driver Education/Training", "Required", "Voluntary"],
      ["Blood Alcohol Content", "Zero", "Zero"],
      [
        "Restrictions",
        "Limitations in place",
        "Yes: no driving between 12 AM – 5 AM"
      ],
      ["Passengers", "Limitations in place", "Limit of 2"],
      ["Sign on Vehicle", "Required", "Mandatory"]
    ],
    BoosterSeatLegislation: [
      ["Age", "Limit in place", "9 years"],
      [
        "Height/Weight",
        "Limit in place",
        "9 years 4 feet 9 inches (145 cm); no weight restrictions"
      ],
      ["Public Education", "Yes", "Yes"],
      ["Incentive Program", "Yes", "Yes"],
      ["Non-Compliance Penalties", "Yes", "Yes"],
      ["Driver Responsibility", "Yes", "Yes"]
    ],
    DistractedDrivingLaws: [
      [
        "Fine",
        "$490 - $1200 for first or second offence",
        "$542 first offence $888 second offence"
      ],
      ["Demerits", "3-5 demerit points", "4 demerit points"],
      ["Bans hand-held electronic entertainment devices", "Yes", "Yes"],
      ["Bans hand-held communication devices", "Yes", "Yes"]
    ]
  },
  ON: {
    HelmetLegislationrevised: [
      ["Age", "Applies to all ages", "Applies only to those <18 years of age"],
      ["Penalty", "Fine", "Fine: $60"],
      ["Includes all wheeled activities", "Yes", "No"]
    ],
    GraduatedDriversLicensing: [
      [
        "Supervisor Driver",
        "Required",
        "Yes (fully licensed, with at least 4 years driving experience)"
      ],
      ["Minimum Age", "Yes", "16 years"],
      ["Driver Education/Training", "Required", "Voluntary"],
      ["Blood Alcohol Content", "Zero", "Zero"],
      [
        "Restrictions",
        "Limitations in place",
        "Yes: no driving between 12 AM – 5 AM"
      ],
      ["Passengers", "Limitations in place", "Limit to number of seatbelts"],
      ["Sign on Vehicle", "Required", "None"]
    ],
    BoosterSeatLegislation: [
      ["Age", "Limit in place", "8 years"],
      [
        "Height/Weight",
        "Limit in place",
        "4 feet 9 inches (145 cm); 80 lbs (36 kg)"
      ],
      ["Public Education", "Yes", "Yes"],
      ["Incentive Program", "Yes", "Yes"],
      ["Non-Compliance Penalties", "Yes", "Yes"],
      ["Driver Responsibility", "Yes", "Yes"]
    ],
    DistractedDrivingLaws: [
      ["Fine", "$490 - $1200 for first or second offence", "$490"],
      ["Demerits", "3-5 demerit points", "3 demerit points"],
      ["Bans hand-held electronic entertainment devices", "Yes", "Yes"],
      ["Bans hand-held communication devices", "Yes", "Yes"]
    ]
  },
  MB: {
    HelmetLegislationrevised: [
      ["Age", "Applies to all ages", "Applies only to those <18 years of age"],
      ["Penalty", "Fine", "Fine up to: $50"],
      ["Includes all wheeled activities", "Yes", "No"]
    ],
    GraduatedDriversLicensing: [
      [
        "Supervisor Driver",
        "Required",
        "Yes (fully licensed for at least 3 years)"
      ],
      [
        "Minimum Age",
        "Yes",
        "16 years or 15 years (if enrolled in the high school education program)"
      ],
      ["Driver Education/Training", "Required", "Voluntary"],
      ["Blood Alcohol Content", "Zero", "Zero"],
      ["Restrictions", "Limitations in place", "No"],
      [
        "Passengers",
        "Limitations in place",
        "Limited to 1 supervising driver in the front seat, and number of working seatbelts"
      ],
      ["Sign on Vehicle", "Required", "None"]
    ],
    BoosterSeatLegislation: [
      ["Age", "Limit in place", "9 years"],
      [
        "Height/Weight",
        "Limit in place",
        "4 feet 9 inches (145 cm); 80 lbs (36 kg)"
      ],
      ["Public Education", "Yes", "Yes"],
      ["Incentive Program", "Yes", "No"],
      ["Non-Compliance Penalties", "Yes", "Yes (since 2013)"],
      ["Driver Responsibility", "Yes", "Yes"]
    ],
    DistractedDrivingLaws: [
      ["Fine", "$490 - $1200 for first or second offence", "$200"],
      ["Demerits", "3-5 demerit points", "5 demerit points"],
      ["Bans hand-held electronic entertainment devices", "Yes", "No"],
      ["Bans hand-held communication devices", "Yes", "Yes"]
    ]
  },
  NB: {
    HelmetLegislationrevised: [
      ["Age", "Applies to all ages", "Applies to all ages"],
      ["Penalty", "Fine", "Fine: $21"],
      ["Includes all wheeled activities", "Yes", "No"]
    ],
    GraduatedDriversLicensing: [
      [
        "Supervisor Driver",
        "Required",
        "Yes (fully licensed, seated in the front seat)"
      ],
      ["Minimum Age", "Yes", "16 years"],
      ["Driver Education/Training", "Required", "Voluntary"],
      ["Blood Alcohol Content", "Zero", "Zero"],
      [
        "Restrictions",
        "Limitations in place",
        "Yes: no driving between 12 AM – 5 AM"
      ],
      ["Passengers", "Limitations in place", "No passengers except supervisor"],
      ["Sign on Vehicle", "Required", "None"]
    ],
    BoosterSeatLegislation: [
      ["Age", "Limit in place", "9 years"],
      [
        "Height/Weight",
        "Limit in place",
        "4 feet 9 inches (145 cm); 80 lbs (36 kg)"
      ],
      ["Public Education", "Yes", "Yes"],
      ["Incentive Program", "Yes", "No"],
      ["Non-Compliance Penalties", "Yes", "Yes"],
      ["Driver Responsibility", "Yes", "Yes"]
    ],
    DistractedDrivingLaws: [
      ["Fine", "$490 - $1200 for first or second offence", "$172.50"],
      ["Demerits", "3-5 demerit points", "3 demerit points"],
      ["Bans hand-held electronic entertainment devices", "Yes", "No"],
      ["Bans hand-held communication devices", "Yes", "Yes"]
    ]
  },
  NL: {
    HelmetLegislationrevised: [
      ["Age", "Applies to all ages", "Applies to all ages"],
      ["Penalty", "Fine", "Fine up to: $100"],
      ["Includes all wheeled activities", "Yes", "No"]
    ],
    GraduatedDriversLicensing: [
      [
        "Supervisor Driver",
        "Required",
        "Yes (four years of driving experience)"
      ],
      ["Minimum Age", "Yes", "16 years"],
      ["Driver Education/Training", "Required", "Voluntary"],
      ["Blood Alcohol Content", "Zero", "Zero"],
      [
        "Restrictions",
        "Limitations in place",
        "Yes: no driving between 12 AM – 5 AM"
      ],
      ["Passengers", "Limitations in place", "No passengers except supervisor"],
      ["Sign on Vehicle", "Required", "Mandatory"]
    ],
    BoosterSeatLegislation: [
      ["Age", "Limit in place", "9 years"],
      [
        "Height/Weight",
        "Limit in place",
        "4 feet 9 inches (145 cm); 80 lbs (36 kg)"
      ],
      ["Public Education", "Yes", "No"],
      ["Incentive Program", "Yes", "No"],
      ["Non-Compliance Penalties", "Yes", "No"],
      ["Driver Responsibility", "Yes", "No"]
    ],
    DistractedDrivingLaws: [
      ["Fine", "$490 - $1200 for first or second offence", "$100-$400"],
      ["Demerits", "3-5 demerit points", "3 demerit points"],
      ["Bans hand-held electronic entertainment devices", "Yes", "No"],
      ["Bans hand-held communication devices", "Yes", "Yes"]
    ]
  },
  NS: {
    HelmetLegislation: [
      ["Age", "Applies to all ages", "Applies to all ages"],
      ["Penalty", "Fine", "Fine minimum: $25"],
      ["Includes all wheeled activities", "Yes", "No"]
    ],
    HelmetLegislationrevised: [
      ["Age", "Applies to all ages", "Applies to all ages"],
      ["Penalty", "Fine", "Fine minimum: $25"],
      ["Includes all wheeled activities", "Yes", "Yes"]
    ],
    GraduatedDriversLicensing: [
      [
        "Supervisor Driver",
        "Required",
        "Yes (experienced driver with at least a class 5 license)"
      ],
      ["Minimum Age", "Yes", "16 years"],
      [
        "Driver Education/Training",
        "Required",
        "Mandatory (long course: 25 hours in class & 10 hours in car; short course: 6 hours in class)"
      ],
      ["Blood Alcohol Content", "Zero", "Zero"],
      [
        "Restrictions",
        "Limitations in place",
        "May drive after midnight with supervisor"
      ],
      ["Passengers", "Limitations in place", "No passengers except supervisor"],
      ["Sign on Vehicle", "Required", "None"]
    ],
    BoosterSeatLegislation: [
      ["Age", "Limit in place", "9 years"],
      [
        "Height/Weight",
        "Limit in place",
        "4 feet 9 inches (145 cm); no weight restrictions"
      ],
      ["Public Education", "Yes", "Yes"],
      ["Incentive Program", "Yes", "Yes"],
      ["Non-Compliance Penalties", "Yes", "Yes"],
      ["Driver Responsibility", "Yes", "Yes"]
    ],
    DistractedDrivingLaws: [
      ["Fine", "$490 - $1200 for first or second offence", "$233.95 - $578.95"],
      ["Demerits", "3-5 demerit points", "4 demerit points"],
      ["Bans hand-held electronic entertainment devices", "Yes", "No"],
      ["Bans hand-held communication devices", "Yes", "Yes"]
    ]
  },
  PEI: {
    HelmetLegislationrevised: [
      ["Age", "Applies to all ages", "Applies to all ages"],
      ["Penalty", "Fine", "Fine up to: $100"],
      ["Includes all wheeled activities", "Yes", "No"]
    ],
    GraduatedDriversLicensing: [
      [
        "Supervisor Driver",
        "Required",
        "Yes (Has valid license for at least 4 years for same class of vehicle)"
      ],
      [
        "Minimum Age",
        "Yes",
        "16 years or 15 years (if enrolled in the high school education program)"
      ],
      ["Driver Education/Training", "Required", "Voluntary"],
      ["Blood Alcohol Content", "Zero", "Zero"],
      [
        "Restrictions",
        "Limitations in place",
        "Yes:  Refrain from driving between 1 a.m. and 5 a.m. for drivers under 21 years"
      ],
      [
        "Passengers",
        "Limitations in place",
        "No passengers, except supervisor or family members"
      ],
      ["Sign on Vehicle", "Required", "Mandatory"]
    ],
    BoosterSeatLegislation: [
      ["Age", "Limit in place", "9 years"],
      [
        "Height/Weight",
        "Limit in place",
        "4 feet 9 inches (145 cm); 80 lbs (36 kg)"
      ],
      ["Public Education", "Yes", "Yes"],
      ["Incentive Program", "Yes", "Yes"],
      ["Non-Compliance Penalties", "Yes", "Yes"],
      ["Driver Responsibility", "Yes", "Yes"]
    ],
    DistractedDrivingLaws: [
      ["Fine", "$490 - $1200 for first or second offence", "$500-$1200"],
      ["Demerits", "3-5 demerit points", "5 demerit points"],
      ["Bans hand-held electronic entertainment devices", "Yes", "No"],
      ["Bans hand-held communication devices", "Yes", "Yes"]
    ]
  },
  SK: {
    GraduatedDriversLicensing: [
      [
        "Supervisor Driver",
        "Required",
        "Yes (occupies the front passenger seat)"
      ],
      [
        "Minimum Age",
        "Yes",
        "16 years or 15 years (if enrolled in the high school education program)"
      ],
      [
        "Driver Education/Training",
        "Required",
        "Mandatory for all new drivers"
      ],
      ["Blood Alcohol Content", "Zero", "Zero"],
      [
        "Restrictions",
        "Limitations in place",
        "Yes: Only immediate family permitted in vehicle between midnight and 5 a.m."
      ],
      ["Passengers", "Limitations in place", "Limited to number of seatbelts"],
      ["Sign on Vehicle", "Required", "None"]
    ],
    BoosterSeatLegislation: [
      ["Age", "Limit in place", "7 years"],
      [
        "Height/Weight",
        "Limit in place",
        "4 feet 9 inches (145 cm); 80 lbs (36 kg)"
      ],
      ["Public Education", "Yes", "Yes"],
      ["Incentive Program", "Yes", "Yes (since 2014)"],
      ["Non-Compliance Penalties", "Yes", "Yes (since 2014)"],
      ["Driver Responsibility", "Yes", "No"]
    ],
    DistractedDrivingLaws: [
      ["Fine", "$490 - $1200 for first or second offence", "$280"],
      ["Demerits", "3-5 demerit points", "4 demerit points"],
      ["Bans hand-held electronic entertainment devices", "Yes", "No"],
      ["Bans hand-held communication devices", "Yes", "Yes"]
    ]
  }
};

const hyperlinks = {
  Falls: [
    [
      "Fall Prevention",
      "https://parachute.ca/en/injury-topic/fall-prevention-for-children"
    ]
  ],
  Cyclist: [
    [
      "Helmet Legislation Policy",
      "https://parachute.ca/wp-content/uploads/2019/08/Bicycle-Helmet-Canadian-Legislation-Chart.pdf"
    ]
  ],
  Transport: [
    [
      "Distracted Drivers Legislation",
      "https://parachute.ca/wp-content/uploads/2019/08/Distracted-Driving-Canadian-Legislation-Chart.pdf"
    ],
    [
      "Review of Graduated Licensing",
      "https://tirf.ca/projects/meta-analysis-gdl-evaluation-studies/"
    ]
  ],
  Pedestrian: [
    [
      "Pedestrian Safety",
      "https://parachute.ca/en/injury-topic/pedestrian-safety/"
    ]
  ],
  Poisoning: [
    [
      "Smoke & CO Detector Laws",
      "https://parachute.ca/wp-content/uploads/2019/08/Carbon-Monoxide-Detector-and-Smoke-Alarm-Canadian-Legislation-Chart.pdf"
    ]
  ],
  Burns: [
    [
      "Injury Topic - Burns",
      "https://parachute.ca/en/injury-topic/burns-and-scalds/"
    ],
    [
      "Prevention & Education",
      "http://burnfund.org/prevention-and-education/too-hot-for-tots/"
    ]
  ],
  Choking: [
    [
      "Product Safety",
      "http://www.parachutecanada.org/policy/item/product-safety"
    ]
  ],
  Occupant: [
    [
      "Booster Seat Legislation Chart",
      "https://parachute.ca/en/professional-resource/policy/booster-seat-legislation/"
    ],
    [
      "Status report on Canadian public policy & child & youth health",
      "https://www.cps.ca/uploads/advocacy/SR16_ENG.pdf"
    ]
  ],
  Drowning: [
    [
      "Pool & Fencing Legislation",
      "https://parachute.ca/en/professional-resource/policy/pool-fencing-laws/"
    ]
  ]
};

export default {
  dataLineGraph,
  TimelinePopUpInfo,
  dataBarGraph,
  policyData,
  provinceIntroPara,
  BarChartSectiondataInfo,
  TimelinedataParagraph,
  ScoreCardRatingInfo,
  hyperlinks
};
// AB:
//    BC:
//   MB:
//   NB:
//   NL:
//    NS:
//   ON:
//   PEI:
//   SK:

// Falls:
//     Transport:
//      Cyclist:
//     Pedestrian:
//      Poisoning:
//     Burns:
//      Choking:
//     Drowning:
//     Occupant:

// graduated: [
//   {
//     header: 'EXELLENT | 3',
//     body:
//       "elimination of 'time discounts' for drivers who attend education programmes",
//   },
//   {
//     header: 'GOOD | 2',
//     body: 'madatory requirement for supervised practice of at least 50 hours',
//   },
//   {
//     header: 'FAIR| 1',
//     body: "minimum 12 months' duration in the learners phase",
//   },
//   {
//     header: 'NONE | 0',
//     body: 'less than 12 months of duration in the learner phase',
//   },
