import React, { useEffect, useState } from 'react';
import './OverallTableBody.scss';
import { withRouter } from 'react-router';

function OverallTableBody({ match }) {
  const { id } = match.params;
  const [highlightArray, setHighlightArray] = useState(null);

  useEffect(() => {
    if (highlightArray) {
      for (var i = 0; i < highlightArray.length; i++) {
        highlightArray[i].classList.remove('highlight');
      }
    }
    let highlight = document.getElementsByClassName(id);

    setHighlightArray(highlight);
  }, [id, highlightArray]);

  useEffect(() => {
    if (highlightArray) {
      for (var i = 0; i < highlightArray.length; i++) {
        highlightArray[i].classList.add('highlight');
      }
    }
  }, [highlightArray]);

  return (
    <React.Fragment>
      <tr>
        <th className='province-header BC mid' data-province='BC'>
          BRITISH COLUMBIA
        </th>
        <td className='BC td-overall'>8</td>
        <td className='BC td-overall'>9</td>
        <td className='BC td-overall'>8</td>
        <td className='BC td-overall'>6</td>
        <td className='BC td-overall'>8</td>
        <td className='total-data BC'>39</td>
        <td className='total-data BC'>1</td>
      </tr>
      <tr>
        <th className='province-header ON pale' data-province='ON'>
          ONTARIO
        </th>
        <td className='ON'>9</td>
        <td className='ON'>3</td>
        <td className='ON'>9</td>
        <td className='ON'>7</td>
        <td className='ON'>9</td>
        <td className='total-data ON'>37</td>
        <td className='total-data ON'>2</td>
      </tr>
      <tr>
        <th className='province-header AB mid' data-province='AB'>
          ALBERTA
        </th>
        <td className='AB'>4</td>
        <td className='AB'>4</td>
        <td className='AB'>7</td>
        <td className='AB'>8</td>
        <td className='AB'>2</td>
        <td className='total-data AB'>25</td>
        <td className='total-data AB'>3</td>
      </tr>
      <tr>
        <th className='province-header NS pale' data-province='NS'>
          NOVA SCOTIA
        </th>
        <td className='NS'>6</td>
        <td className='NS'>2</td>
        <td className='NS'>5</td>
        <td className='NS'>5</td>
        <td className='NS'>7</td>
        <td className='total-data NS'>25</td>
        <td className='total-data NS'>3</td>
      </tr>
      <tr>
        <th className='province-header MB mid' data-province='MB'>
          MANITOBA
        </th>
        <td className='MB'>7</td>
        <td className='MB'>6</td>
        <td className='MB'>3</td>
        <td className='MB'>2</td>
        <td className='MB'>5</td>
        <td className='total-data MB'>23</td>
        <td className='total-data MB'>5</td>
      </tr>
      <tr>
        <th className='province-header PEI pale' data-province='PEI'>
          PRINCE EDWARD ISLAND
        </th>
        <td className='PEI'>2</td>
        <td className='PEI'>1</td>
        <td className='PEI'>4</td>
        <td className='PEI'>9</td>
        <td className='PEI'>7</td>
        <td className='total-data PEI'>23</td>
        <td className='total-data PEI'>5</td>
      </tr>
      <tr>
        <th className='province-header NL mid' data-province='NL'>
          NEWFOUNDLAND & LABRADOR
        </th>
        <td className='NL'>5</td>
        <td className='NL'>5</td>
        <td className='NL'>6</td>
        <td className='NL'>3</td>
        <td className='NL'>3</td>
        <td className='total-data NL'>22</td>
        <td className='total-data NL'>7</td>
      </tr>
      <tr>
        <th className='province-header NB pale' data-province='NB'>
          NEW BRUNSWICK
        </th>
        <td className='NB'>3</td>
        <td className='NB'>7</td>
        <td className='NB'>2</td>
        <td className='NB'>4</td>
        <td className='NB'>5</td>
        <td className='total-data NB'>21</td>
        <td className='total-data NB'>8</td>
      </tr>
      <tr>
        <th className='province-header SK mid' data-province='SK'>
          SASKATCHEWAN
        </th>
        <td className='SK'>1</td>
        <td className='SK'>8</td>
        <td className='SK'>1</td>
        <td className='SK'>1</td>
        <td className='SK'>1</td>
        <td className='total-data SK'>19</td>
        <td className='total-data SK'>9</td>
      </tr>
    </React.Fragment>
  );
}

export default withRouter(OverallTableBody);
