import React from 'react';
import './HomepageIntro.scss';
import BarChartHomeIntro from '../BarChartHomeIntro';

function HomePageIntro() {
  return (
    <div className='whole-home-intro'>
      <div className='barchart-home-intro'>
        <BarChartHomeIntro injury='AV' />
      </div>

      <p className='para-after'>
        In Canada, over the 7 year study period the overall injury
        hospitalization rate from all causes was 567.87 per 100,000. Between
        2006 and 2012 this rate decreased by -2.66%. The ranking in order from
        the highest rate of injury hospitalization (lowest score) to lowest
        hospitalization rate (highest score) was Saskatchewan, Prince Edward
        Island, New Brunswick, Alberta, Newfoundland, Nova Scotia, Manitoba,
        British Columbia and Ontario. Saskatchewan’s average injury
        hospitalization rate was 907.82 per 100,000 (nearly double that of the
        Canadian average) and Ontario’s rate was 460.13 per 100,000.
      </p>
    </div>
  );
}

export default HomePageIntro;
