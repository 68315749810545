import React from "react";
import "./LineChartBackground.scss";
import LineChart from "../LineChart";
import { PROVINCES } from "../../constants";
import { withRouter } from "react-router";

function LineChartBackground({ match }) {
  const { id } = match.params;

  return (
    <div
      className="linechart-background"
      style={
        id
          ? {
              backgroundColor: "#F4F4F4",
              backgroundImage: `url(/line-graph-background/${id}.svg)`,
              backgroundRepeat: "no-repeat"
            }
          : {
              backgroundColor: "#F4F4F4",
              backgroundImage: `url(/line-graph-background/Canada.svg)`,
              backgroundRepeat: "no-repeat"
            }
      }
    >
      <span className="injury-title">INJURIES</span>
      <div className="line-chart-div">
        <LineChart
          provinceShort={id ? id : "Canada"}
          provinceLong={id ? PROVINCES[id] : "Canada"}
        />
      </div>
      <div className="spacing"></div>
    </div>
  );
}

export default withRouter(LineChartBackground);
