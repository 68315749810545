import React from "react";
import "./MobileView.scss";
import ReactGA from "react-ga";

function MobileView() {
  const handleClick = () => {
    //google analytics
    ReactGA.event({
      category: "Click Mobile PDF",
      action: "Mobile PDF downloaded"
    });
  };
  return (
    <div
      className="mobile-container"
      style={{
        backgroundImage: `url(/map-image/MOBILE-map.png)`,
        backgroundRepeat: "no-repeat"
      }}
    >
      <div
        className="canadian-title-mobile"
        style={{
          backgroundImage: `url(/province-title/Canada-RC-mobile.svg)`,
          backgroundRepeat: "no-repeat"
        }}
      />

      <div className="paragraph-mobile">
        The Canadian Child Safety Report Card was prepared using data from the
        Canadian Institute for Health Information (CIHI) and Provincial Coronial
        Data. Injury hospitalizations and deaths from all causes for children
        and adolescents aged 0 – 19 were analyzed between 2006 and 2012.
        <br />
        <br />
        <br />
        This website is best viewed on desktop, for your convenience download
        the below pdf to view the Canadian Child Safety Report Card.
        <a
          onClick={handleClick}
          className="pdf-button"
          download
          href="/CSRC-pdf/Canadian-Child-Safety-Report-Card.pdf"
        >
          DOWNLOAD PDF
        </a>
      </div>
      <div className="contact-mobile">
        <span className="about-header-mobile contact-1-mobile con">
          CONTACT US
        </span>
        <div className="contact-2-mobile">
          <h3 className="about-sub-header-mobile">Head office</h3>
          <p className="about-sub-info-mobile">
            150 Eglinton Ave East
            <br /> Suite 300 <br />
            Toronto, Ontario
            <br /> M4P 1E8
          </p>
        </div>
        <div className="contact-3-mobile">
          <div>
            <h3 className="about-sub-header-mobile">Telephone</h3>
            <p className="about-sub-info-mobile">647-776-5100</p>
            <h3 className="about-sub-header-mobile">Toll-free</h3>
            <p className="about-sub-info-mobile">1-888-537-7777</p>
          </div>
        </div>
        <div className="contact-4-mobile">
          <div>
            <h3 className="about-sub-header-mobile">Email</h3>
            <p className="about-sub-info-mobile">info@parachute.ca</p>
            <h3 className="about-sub-header-mobile">
              For media enquired, please contact
            </h3>
            <p className="about-sub-info-mobile"> media@parachute.ca </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MobileView;
