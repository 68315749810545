import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Appbar.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { PROVINCES } from '../../constants';
import { withRouter } from 'react-router';
import ReactGA from 'react-ga';

const handleMouseDown = event => {
  event.target.style.backgroundColor = '#42c0ad';
  const provinceClicked = event.target.innerHTML;
  ReactGA.event({
    category: 'Navigation',
    action: `clicked on nav bar to redirect to ${provinceClicked} province page`
  });
};

const handleMouseUp = event => {
  event.target.style.backgroundColor = '#FFFFFF';
};

function Appbar({ location, logoIsVisible }) {
  const [showLogo, setshowLogo] = useState(true);
  useEffect(() => {
    if (location.pathname === '/' && logoIsVisible) {
      setshowLogo(false);
    } else {
      setshowLogo(true);
    }
  }, [logoIsVisible, location.pathname]);

  return (
    <Navbar className='navbar' fixed='top'>
      <Navbar.Brand
        className='home-link'
        href='/'
        style={{
          backgroundImage: 'url(/CCSRC-logo.svg)',
          opacity: showLogo ? 1 : 0,
          backgroundRepeat: 'no-repeat',
          height: '8vw',
          width: '10vw'
        }}
      ></Navbar.Brand>
      <Navbar.Toggle aria-controls='basic-navbar-nav' />
      <Navbar.Collapse id='basic-navbar-nav'>
        <Nav className='ml-auto'>
          <NavDropdown
            active={location.pathname.startsWith('/provinces')}
            title='PROVINCES'
            className='provinces'
            id='basic-nav-dropdown'
            role='tablist' //dropdown list alert for accessability
          >
            {Object.keys(PROVINCES).map(id => (
              <NavDropdown.Item
                className='drop-down-titles'
                key={id}
                as={Link}
                to={`/provinces/${id}`}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
              >
                {PROVINCES[id]}
              </NavDropdown.Item>
            ))}
          </NavDropdown>
        </Nav>
      </Navbar.Collapse>
      <Navbar.Brand
        className='home-link about'
        as={Link}
        to={`/about`}
        style={{
          color: location.pathname === '/about' ? '#42c0ad' : '#bbd431',
          borderBottom:
            location.pathname === '/about' ? '0.5vw solid #42c0ad' : ''
        }}
      >
        ABOUT
      </Navbar.Brand>
    </Navbar>
  );
}

export default withRouter(Appbar);
