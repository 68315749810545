import React from "react";
import { Link } from "react-router-dom";
import "./Footer.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright } from "@fortawesome/free-regular-svg-icons";
import Clock from "react-live-clock";

function Footer() {
  return (
    <div className="footer">
      <Link className="contact-link" as={Link} to={"/about"}>
        Contact
      </Link>
       <Link className="contact-link" as={Link} to={"/privacy"}>
        Privacy Policy
      </Link>
      <div className="copyright">
        <FontAwesomeIcon icon={faCopyright} className="copyright-icon" />
        <span>
          {" "}
          Copyright <Clock format={"YYYY"} timezone={"US/Pacific"} /> - All
          Rights Reserved
        </span>
      </div>
    </div>
  );
}

export default Footer;
