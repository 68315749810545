import React, { useState, useEffect } from 'react';
import './Map.scss';
import { ReactComponent as Canada } from './map-and-title.svg';
import ReactTooltip from 'react-tooltip';
import { withRouter } from 'react-router';
import ReactGA from 'react-ga';

function Map({ history }) {
  // eslint-disable-next-line no-unused-vars
  const [provinceShort, setProvinceShort] = useState('');
  // eslint-disable-next-line no-unused-vars
  const [provinceLong, setProvinceLong] = useState('');

  useEffect(() => {
    const provinces = document.getElementsByClassName('province');

    const onClick = event => {
      let provinceShortClicked = event.target.getAttribute('data-province');
      let provinceLongClicked = event.target.getAttribute('data-tip');
      let provinceLongNoBreaks = provinceLongClicked.split('<br />').join(' ');
      setProvinceShort(provinceShortClicked);

      setProvinceLong(provinceLongNoBreaks);
      history.push(`/provinces/${provinceShortClicked}`);
      //google analytics custom events:
      ReactGA.event({
        category: 'Map interaction',
        action: `clicked on ${provinceShortClicked} province on map to redirect to ${provinceShortClicked} page`
      });
    };

    Array.from(provinces).forEach(function(province) {
      province.addEventListener('click', onClick);
    });

    //add event lister to all NL elements so that they change color on hover together (due to SVG layout this is the only province which this is necessary for)
    function classToggle(evt, find, toggle) {
      [].forEach.call(document.querySelectorAll('.' + find), function(a) {
        a.classList[evt.type === 'mouseover' ? 'add' : 'remove'](toggle);
      });
    }

    //for NL
    const elsNL = document.querySelectorAll('.NFL');
    for (let i = 0, len = elsNL.length; i < len; i++) {
      elsNL[i].addEventListener('mouseover', function(e) {
        classToggle(e, 'NFL', 'highlight');
      });
      elsNL[i].addEventListener('mouseout', function(e) {
        classToggle(e, 'NFL', 'highlight');
      });
    }

    //for BC
    const elsBC = document.querySelectorAll('.bc-click');
    for (let i = 0, len = elsBC.length; i < len; i++) {
      elsBC[i].addEventListener('mouseover', function(e) {
        classToggle(e, 'bc-click', 'highlight');
      });
      elsBC[i].addEventListener('mouseout', function(e) {
        classToggle(e, 'bc-click', 'highlight');
      });
    }

    //for ON
    const elsON = document.querySelectorAll('.ONT');
    for (let i = 0, len = elsON.length; i < len; i++) {
      elsON[i].addEventListener('mouseover', function(e) {
        classToggle(e, 'ONT', 'highlight');
      });
      elsON[i].addEventListener('mouseout', function(e) {
        classToggle(e, 'ONT', 'highlight');
      });
    }

    //for NS
    const elsNS = document.querySelectorAll('.NVS');
    for (let i = 0, len = elsNS.length; i < len; i++) {
      elsNS[i].addEventListener('mouseover', function(e) {
        classToggle(e, 'NVS', 'highlight');
      });
      elsNS[i].addEventListener('mouseout', function(e) {
        classToggle(e, 'NVS', 'highlight');
      });
    }
  });

  return (
    <>
      <div className='map'>
        <div className='tooltip-container'>
          <ReactTooltip multiline={true} className='tooltip' place='top' />
        </div>
        <Canada className='canada-map' />
        <p className='map-paragraph'>
          The Canadian Child Safety Report Card was prepared using data from the
          Canadian Institute for Health Information (CIHI) and Provincial
          Coronial Data. Injury hospitalizations and deaths from all causes for
          children and adolescents aged 0 – 19 were analyzed between 2006 and
          2012.
        </p>
        <div className='arrow'></div>
      </div>
    </>
  );
}

export default withRouter(Map);
