import React, { useEffect, useState } from "react";
import {
  BarChart,
  CartesianAxis,
  ReferenceLine,
  XAxis,
  YAxis,
  Label,
  Bar,
  ResponsiveContainer
} from "recharts";
import anime from "animejs";
import "../BarChart/BarChart.scss";
import data from "../../data.js";
import { CHARTCOLORSBAR } from "../../constants.js";
import { withBreakpoints } from "react-breakpoints";
import ReactGA from "react-ga";

function BarChartHomeIntro({ injury, currentBreakpoint, breakpoints }) {
  const dataArray = data.dataBarGraph.map(d => ({
    name: d.name,
    [injury]: d[injury]
  }));
  //set avg value for reference line
  let values = dataArray.map(d => d[injury]);
  const avg =
    values.reduce((a, b) => {
      return a + b;
    }) / values.length;

  const [hoveredValue, setHoveredValue] = useState(null);

  const handleMouseEnter = event => {
    setHoveredValue(event.value);
    //google analytics custom events:
    ReactGA.event({
      category: "Chart interaction",
      action: "hovered on Canada barChart on home page"
    });
  };

  const handleMouseClick = event => {
    setHoveredValue(event.value);
    //google analytics custom events:
    ReactGA.event({
      category: "Chart interaction",
      action: "clicked on Canada barChart on home page"
    });
  };

  const setAnimation = () => {
    anime({
      targets: ".bar-new-label",
      // eslint-disable-next-line no-dupe-keys
      translateY: [{ value: 40, value: -25 }],
      opacity: [0, 1],
      duration: 500,
      easing: "easeOutSine"
    });
  };

  useEffect(() => {
    setTimeout(() => {
      setAnimation();
    }, 1200);
  }, [injury]);

  useEffect(() => {
    setAnimation();
  }, [hoveredValue]);

  return (
    <div className="graph-3">
      <h1
        className="bar-chart-intro-title"
        style={{
          marginBottom: "1vw",
          fontWeight: "bold",
          fontSize: "1.5vw"
        }}
      >
        Population Based Injury Hospitalization Rate per 100,000 from All Causes
        in Canada
      </h1>
      <ResponsiveContainer
        className="bar-chart-responsive"
        aspect={
          breakpoints[currentBreakpoint] > breakpoints.tablet
            ? 1 / 0.6
            : 1.5 / 1
        }
      >
        <BarChart
          className="chart-container-bar-intro"
          data={dataArray}
          margin={30}
        >
          <CartesianAxis x={0} />
          <XAxis type="category" dataKey="name" fontSize="1.1vw">
            <Label
              className="label label-home-bar-x"
              value="PROVINCE"
              dy={
                breakpoints[currentBreakpoint] >= breakpoints.tabletLandscape
                  ? 22
                  : -5
              }
              fontSize={"1vw"}
              position="bottom"
            />
          </XAxis>
          <YAxis fontSize="1.1vw" allowDecimals={false}>
            <Label
              className="label label-home-bar-y"
              value="AV. RATE PER 100,000 POPULATION"
              dx={
                breakpoints[currentBreakpoint] > breakpoints.tabletLandscape
                  ? -30
                  : 15
              }
              angle={-90}
              fontSize={"1vw"}
              position="left"
              style={{ textAnchor: "middle" }}
            />
          </YAxis>
          <Bar
            dataKey={injury}
            fill={CHARTCOLORSBAR[injury][0]}
            onMouseEnter={handleMouseEnter}
            barSize={
              breakpoints[currentBreakpoint] > breakpoints.tabletLandscape
                ? 40
                : 30
            }
            onClick={handleMouseClick}
            animationDuration={800}
            label={{
              content: ({ value, content, ...labelProps }) => {
                if (hoveredValue === value) {
                  return (
                    <Label
                      style={{ opacity: 0, fontWeight: "normal" }}
                      className="bar-new-label"
                      position="insideTop"
                      fill="black"
                      value={value}
                      {...labelProps}
                      fontSize={"0.8vw"}
                    />
                  );
                }
              }
            }}
          />
          <ReferenceLine
            className="ref-line"
            y={avg}
            stroke="#EA3B24"
            strokeDasharray="3 3"
            isFront
          >
            <Label
              className="label"
              value="CANADIAN AVG"
              position="right"
              fill="#EA3B24"
              fontSize={"0.8vw"}
            />
            <Label
              className="label"
              value={avg.toFixed(2)}
              position="right"
              dy={
                breakpoints[currentBreakpoint] > breakpoints.tabletLandscape
                  ? 25
                  : 10
              }
              fill="#EA3B24"
              fontSize={"0.8vw"}
            />
          </ReferenceLine>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
export default withBreakpoints(BarChartHomeIntro);
