import React from 'react';

function OverallHeader() {
  return (
    <React.Fragment>
      <th className='table-header'>
        POPULATION BASED
        <br /> HOSPITALIZATION
      </th>
      <th className='table-header'>
        PERCENT CHANGE IN
        <br /> HOSPITALIZATION RATE
      </th>
      <th className='table-header'>
        POPULATION BASED
        <br /> MORTALITY RATE
      </th>
      <th className='table-header'>
        PERCENT CHANGE <br />
        MORTALITY
      </th>
      <th className='table-header'>
        EVIDENCE BASED
        <br /> POLICY SCORE
      </th>
      <th className='total-header'>
        OVERALL
        <br /> SCORE
      </th>
      <th className='total-header'>PLACE</th>
    </React.Fragment>
  );
}

export default OverallHeader;
