import React, { useEffect, useState } from "react";
import {
  BarChart,
  CartesianAxis,
  ReferenceLine,
  XAxis,
  YAxis,
  Label,
  Bar,
  ResponsiveContainer
} from "recharts";
import anime from "animejs";
import "./BarChart.scss";
import data from "../../data.js";
import { CHARTCOLORSBAR } from "../../constants.js";
import { withRouter } from "react-router";
import TrackVisibility from "react-on-screen";
import { withBreakpoints } from "react-breakpoints";
import ReactGA from "react-ga";

function BarChartComponent({ injury, match, currentBreakpoint, breakpoints }) {
  const { id } = match.params;
  const dataArray = data.dataBarGraph.map(d => ({
    name: d.name,
    [injury]: d[injury]
  }));
  //set avg value for reference line
  let values = dataArray.map(d => d[injury]);
  const avg =
    values.reduce((a, b) => {
      return a + b;
    }) / values.length;
  const dataPointForProvince = dataArray.find(d => d.name === id);

  const [hoveredValue, setHoveredValue] = useState(
    dataPointForProvince[injury]
  );

  const handleMouseEnter = event => {
    setHoveredValue(event.value);
    //google analytics custom events:
    ReactGA.event({
      category: "Chart interaction",
      action: `hovered on barChart on ${injury} injury section of province page`
    });
  };

  const handleClick = event => {
    setHoveredValue(event.value);
    //google analytics custom events:
    ReactGA.event({
      category: "Chart interaction",
      action: `clicked on barChart on ${injury} injury section of province page`
    });
  };

  const setAnimation = () => {
    anime({
      targets: ".bar-new-label",
      // eslint-disable-next-line no-dupe-keys
      translateY: [{ value: 40, value: -25 }],
      opacity: [0, 1],
      duration: 500,
      easing: "easeOutSine"
    });
  };

  const setHoveredValueForProvince = () => {
    const dataPointForProvince = dataArray.find(d => d.name === id);
    setHoveredValue(dataPointForProvince[injury]);
  };

  useEffect(() => {
    setTimeout(() => {
      setHoveredValueForProvince();
      setAnimation();
    }, 1200);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [injury]);

  useEffect(() => {
    setHoveredValueForProvince();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    setAnimation();
  }, [hoveredValue]);

  useEffect(() => {
    anime({
      targets: ".fade-in",
      opacity: [0, 1],
      duration: 10000,
      loop: false
    });
  });

  return (
    <div className="graph-3">
      <TrackVisibility partialVisibility className="">
        {({ isVisible }) =>
          isVisible && (
            <ResponsiveContainer
              className="bar-chart-responsive "
              aspect={1 / 0.5}
            >
              <BarChart
                className="chart-container-bar"
                data={dataArray}
                margin={{
                  left:
                    breakpoints[currentBreakpoint] > breakpoints.tabletLandscape
                      ? 0
                      : -30
                }}
              >
                <CartesianAxis x={0} />
                <XAxis type="category" dataKey="name" fontSize="1.1vw">
                  <Label
                    className="label"
                    value="PROVINCE"
                    fontSize={"1.1vw"}
                    position="bottom"
                    dy={
                      breakpoints[currentBreakpoint] >
                      breakpoints.tabletLandscape
                        ? 15
                        : -5
                    }
                  />
                </XAxis>
                <YAxis fontSize="1.1vw" allowDecimals={false}>
                  <Label
                    className="label"
                    value="AV. RATE PER 100,000 POPULATION"
                    angle={-90}
                    dx={
                      breakpoints[currentBreakpoint] >
                      breakpoints.tabletLandscape
                        ? -16
                        : 26
                    }
                    fontSize={"1.1vw"}
                    position="left"
                    style={{ textAnchor: "middle" }}
                  />
                </YAxis>
                <Bar
                  dataKey={injury}
                  fill={CHARTCOLORSBAR[injury][1]}
                  onMouseEnter={handleMouseEnter}
                  onClick={handleClick}
                  barSize={
                    breakpoints[currentBreakpoint] > breakpoints.tabletLandscape
                      ? 30
                      : 20
                  }
                  animationDuration={800}
                  label={{
                    content: ({ value, content, ...labelProps }) => {
                      if (hoveredValue === value) {
                        return (
                          <Label
                            style={{ opacity: 0, fontWeight: "normal" }}
                            className="bar-new-label"
                            position="insideTop"
                            fill="black"
                            value={value}
                            {...labelProps}
                            fontSize={"0.8vw"}
                          />
                        );
                      }
                    }
                  }}
                />
                <ReferenceLine
                  className="ref-line"
                  y={avg}
                  stroke="#EA3B24"
                  strokeDasharray="3 3"
                  isFront
                >
                  <Label
                    className="label"
                    value="CANADIAN AVG"
                    position="right"
                    fill="#EA3B24"
                    fontSize={"0.8vw"}
                  />
                  <Label
                    className="label"
                    value={avg.toFixed(2)}
                    position="right"
                    dy={
                      breakpoints[currentBreakpoint] >
                      breakpoints.tabletLandscape
                        ? 25
                        : 10
                    }
                    fill="#EA3B24"
                    fontSize={"0.8vw"}
                    fontWeight="bold"
                  />
                </ReferenceLine>
              </BarChart>
            </ResponsiveContainer>
          )
        }
      </TrackVisibility>
    </div>
  );
}
export default withBreakpoints(withRouter(BarChartComponent));
