import React, { useState, useEffect } from "react";
import "./ScoreCard.scss";
import { withRouter } from "react-router";
import anime from "animejs";
import PolicyHeader from "./PolicyHeader";
import PolicyTableBody from "../PolicyTableBody";
import OverallHeader from "./OverallHeader";
import OverallTableBody from "../OverallTableBody";
import data from "../../data.js";
import ReactGA from "react-ga";

let policyButtons = [
  "GRADUATED DRIVER’S LICENSING",
  "PEDESTRIAN SAFETY",
  "BOOSTER SEAT LEGISLATION",
  "BICYCLE HELMET LEGISLATION",
  "DISTRACTED DRIVING",
  "SMOKE & CO DETECTORS"
];

function ScoreCard({ match, provinceShort, provinceLong }) {
  //province id passed down through props as id
  const { id } = match.params;
  const [hoveredValue, setHoveredValue] = useState("graduated");
  const [scoreCard, setScoreCard] = useState("OVERALL");

  const handleClickScoreButton = event => {
    let hoverInfo = event.target.innerHTML.split(" ")[0].toLowerCase();
    const scoreCardButtons = document.getElementsByClassName("clickable");
    for (var i = 0; i < scoreCardButtons.length; i++) {
      scoreCardButtons[i].classList.remove("highlight");
      event.target.classList.add("highlight");
      setHoveredValue(hoverInfo);
    }
    ReactGA.event({
      category: "Chart interaction",
      action: `clicked on ${hoverInfo} score card tab`
    });
  };

  useEffect(() => {
    anime({
      targets: ".score-card-fade",
      opacity: [0, 1],
      duration: 10000,
      loop: false
    });
  }, [hoveredValue]);

  useEffect(() => {
    const buttons = document.getElementsByClassName("score-card-button");
    if (hoveredValue === "graduated") {
      buttons[0].classList.add("highlight");
    }
  });

  const handleClick = event => {
    setScoreCard(event.target.innerHTML);
    let allSpans = document.getElementsByClassName("current-policy");
    if (allSpans[0] === undefined) {
      event.target.classList.add("current-policy");
    } else {
      allSpans[0].classList.remove("current-policy");
      event.target.classList.add("current-policy");
    }
    //google analytics custom events:
    ReactGA.event({
      category: "Chart interaction",
      action: `clicked on ${scoreCard} toggle button in score card table`
    });
  };

  const renderTableData = hoveredValue => {
    return data.ScoreCardRatingInfo[hoveredValue].map((policy, index) => {
      return (
        <tr className="score-tr" key={index}>
          <td className="score-td scores-table-header">{policy[0]}</td>
          <td className="score-td scores-table-body-1">{policy[1]}</td>
          <td className="score-td scores-table-body-2">{policy[2]}</td>
        </tr>
      );
    });
  };

  return (
    <div className="score-card-container">
      <span className="title-score-card"> SCORE&nbsp;CARD</span>
      <p className="paragraph-under-bottons">
        Overall Rankings were calculated by summing the scores on 5 criteria:
        hospitalization rate, death rate, percent change in morbidity and
        mortality rates, and evidence-based policy score. Please see appendix
        for more information on individual criteria.
      </p>
      <table className="main-score-card-table">
        <tbody>
          <tr className="row-1">
            <th className="toggle-container">
              <span
                className="toggle-view span current-policy"
                variant="secondary"
                onClick={handleClick}
              >
                OVERALL
              </span>
              <span
                className="toggle-view span right"
                variant="secondary"
                onClick={handleClick}
              >
                POLICY
              </span>
            </th>
            {scoreCard === "POLICY" ? (
              <PolicyHeader provinceShort={id} />
            ) : (
              <OverallHeader provinceShort={id} />
            )}
          </tr>
          {scoreCard === "POLICY" ? <PolicyTableBody /> : <OverallTableBody />}
        </tbody>
      </table>
      <div className="button-container">
        {policyButtons.map(function(name, index) {
          return (
            <div
              className="score-card-button clickable"
              key={index}
              onClick={handleClickScoreButton}
            >
              {name}
            </div>
          );
        })}
      </div>
      {hoveredValue && (
        <div
          className="score-card-pop-up-container"
          style={{
            backgroundColor: "#bbd431"
          }}
        >
          <div
            className="score-card-icon score-card-fade"
            style={{
              backgroundImage: `url(/policy-score-card-tab/${hoveredValue}.svg)`,
              backgroundRepeat: "no-repeat"
            }}
          ></div>
          <table className="scores-table">
            <tbody> {renderTableData(hoveredValue)}</tbody>
          </table>
        </div>
      )}
    </div>
  );
}

export default withRouter(ScoreCard);
